
.ibox-content.wf-list{
    padding-bottom: 40px !important;
}

.search-box{
    position: absolute;
    top:49%;
    left:50%;
    transform: translate(-50%,-50%);
    background: #01574e;
    height: 40px;
    border-radius: 40px;
    
}

.search-box:hover > .search-text{
    width: 380px;
    padding: 0 6px;
    padding-left: 15px;
}

.search-btn{
    color:#aaa !important;
    float:right;
    width: 40px;
    height: 40px;
    border-radius:50%; 
    background-color: #01574e;
    justify-content: center !important;
    align-items: center !important;
    font-size: 18px;
    padding: .5rem .5rem !important;
}

.search-btn svg{
    margin: 2px;
}


.search-text{
    border:none;
    background: none;
    float:left;
    padding:0;
    color:white;
    font-size: 16px;
    transform: 0.4s;
    line-height: 40px;
    width: 380px; 
    padding-left: 15px;

    outline: none;
   
}

.search-text::placeholder, .search-text:-ms-input-placeholder, .search-text::-moz-placeholder, .search-text::-webkit-input-placeholder,  .search-text::-moz-placeholder{
    font-weight: red;
}


.search-text::selection{
    border: none;
}

.search-result-box{
    background-color: #f1f1f1;
    width: 400px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,20px);
    overflow-y: scroll;
    max-height: 60vh;

    border-bottom: #ddd 1px solid;
    border-radius: 5px;
}

.search-result-box.hide{
    display: none;
}
.search-result-box.show{
    display: initial;

}

.search-result-box .list-table-mini{
    display: block;
    width: 100%;

}

.search-result-box .list-table-mini *{
    font-size: 14px !important;

}
.search-result-box .list-table-mini .text-muted{
    font-size: 12px !important;
}


.search-result-box .list-table-mini .list-item{
    margin-bottom: 0;
}


.app-footer{
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    background-color: #f1f1f1;
    width: 100%;
}
.appTitle{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    color: white;
    font-family: 'open Sans', sans-serif;
    margin-left: 10px;
    margin-right: 10px;
}
.app-title{
    background-color: #01b5af;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    
    top:0;
    height: 60px;
}
.app-title span{
    padding-top: 5px;
    padding-left: 10px;
    color:white;
    float: left;
    font-size: 2rem;
}

.app-title-actions{
    float: right;
    padding-top:15px;
}

.app-frame{
    padding: 10px 20px;
    margin-top: 55px;
    margin-bottom: 50px;  
}
.app-frame .card{
    background-color: rgba(255,255,255,0.9);
}
.iconLabel{
    margin-left:10px; 
}
.loader-bk{
    background-color: rgba(0,0,0,0.95);
    z-index: 8888;
    align-items: center;
    text-align: center;
    position: fixed;
    top:40%;
    left: 44.5%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;   
}
.loader-cr{
    position: fixed;
    top:40%;
    left: 46%;
    z-index: 8889;

}

.loader-bk .MuiCircularProgress-colorPrimary-74{
    color: rgba(1,181,175, 0.8);
}

.navbarTop {
    z-index: 8890 !important;
    box-shadow: 0px 0px 10px 1px #00211e;
}

.pagination .page-link{
    color:#01b5af;
    
}
.pagination .page-link:hover{
    color:#00695f ;
    
}
.w86{
    width: 50%;
}
.navbar-toggler{
    color: #fff !important;
}
.navbar-toggler-icon{
    color: #fff !important;
}

.app-header{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background-color: #00695f;
    height: 50px;
    z-index: 99;
    align-items: center;
}

.app-header-logo{
    margin-left: 1rem;
}

.app-header .imgLogo{
    width: 300px;
}

.navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navigation .navigation-items ul li a, .navigation .navigation-items ul li a svg{
    color:white;
    text-decoration: none;
    font-size: 1.5rem;
}

.spacer {
    flex: 1;
}

.navigation .navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
   
}

.navigation .navigation-items li {
    padding: 0 0.5rem;
}


.navigation .navigation-items ul li a:hover, .navigation .navigation-items ul li a:active,
.navigation .navigation-items ul li a svg:hover, .navigation .navigation-items ul li a svg:active{
    color: #01b5af 
}


.app-pagination{
    margin-top: 5px;
    height: 30px;
    display: flex;
}
/* .app-pagination ul {
    flex-
} */


.app-drawerbtn{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}
.app-drawerbtn:focus{
    outline: none;
}
.app-drawerbtn .drawerbtn-line{
    width: 30px;
    height: 2px;
    background: white;

}
.app-side-drawer{
    height: 100%;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    z-index: 101;
   
}
.app-side-drawer-left{
    top:0;
    left:-10px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}
.app-side-drawer-right{
    top: 0;
    right: -10px;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.app-side-drawer.open {
    transform: translateX(0);
}

.app-side-navbar {
    width: 70%;
    background-color: #00695f;
}
.app-side-drawer ul{
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
}
.app-side-drawer ul.side-drawer-list-navbar{
    justify-content: center;
    padding-left: 0;
    padding-bottom: 50%;
}

.app-side-drawer ul.side-drawer-list-admin{
    justify-content:start;
    padding-left: 0;
    padding-top: 20px;
}
.app-side-drawer ul.side-drawer-list-admin li{
    padding-left: 25px !important;
}

.app-side-drawer ul li{
    margin-bottom: 20px;
    padding-left: 40px;
}

ul.side-drawer-list-navbar > li:first-child{
    position: absolute;
    top:0;
    margin: 2rem 0;
    padding-bottom: 20px;
    border-bottom: #0a4947b2 2px solid ;
    width: 100%;
   
}

ul.side-drawer-list-navbar > li:last-child{
    position: absolute;
    bottom:0;
    padding: 2rem 0;
    border-top: #0a4947b2 2px  solid;
    width: 100%;
}

.side-drawer-list-navbar a{
    color:white;
    text-decoration: none;
    font-size: 1.2rem;
}
.side-drawer-list-navbar a:hover,.app-side-drawer a:active{
    color:#01b5af;
    text-decoration: none;
}

.side-drawer-list-admin a{
    color:#00695f;
    text-decoration: none;
    font-size: 1.2rem;
}
.side-drawer-list-admin a:hover,.app-side-drawer a:active{
    color:#01b5af;
    text-decoration: none;
}

.app-side-drawer li{
    margin: 0.5rem 0;
}

.app-backdrop{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
}
.h800px{
    height: 800px !important;
}
@media only screen and (min-width:768px) {
    .search-box:hover > .search-text{
        width: 230px;
    }

    .search-text{
        padding-left: 0;
        width: 0px;
    }

    .app-drawerbtn{
        display: none;
    }
}

@media only screen and (min-width:1px) and (max-width: 768px) {
    .navigation-items{
        display: none;
    }

    .search-box{
        position: absolute;
        top:49%;
        left:80%;
    }
    .search-box:hover{
        left:50%;
    }
    .search-box:hover > .search-text{
        width: 300px;
    }
    .search-box:hover > .search-btn{
        position: absolute;
        right: 0px;
        padding: .5rem .5rem;
    }
    .search-text{
        padding-left: 0;
        width: 0px;
    }

    .loader-cr{
        top:40%;
        left: 30%;
    }
    .appTitle{
        font-size: 16px;
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
        
    }

    .app-frame{
        padding:10px; 
        padding-left: 0;
        padding-right: 0;
    }
    
    .expand-body{
        width: 100vw !important;
        margin-left: 0 !important;
        padding: 10px 20px !important;
    }

    .show-body{
        width: 100vw !important;
        margin-left: 0 !important;
        padding: 10px 20px !important;
    }



    
}


/* 
.app-header ul li span{
    font-size: 20px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}
.app-header ul li a,.app-header ul li label {
    font-size: 28px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}

.app-header ul{
    margin-top: 10px;
}
.app-header h2{
    margin-top: 10px;
}
.app-header-sm{
    height: 45px;
    -webkit-transition: height 0.2s; 
    transition: height 0.2s;
}

.app-header-sm h2{
    margin-top: 2px;
}
.app-header-sm ul{
    margin-top: 5px;
}
.app-header-sm ul li a, .app-header-sm ul li label {
    font-size: 20px;
    -webkit-transition: font-size 0.2s;
    transition: font-size 0.2s;
}
.app-header-sm ul li span{
    font-size: 14px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}
.app-header-sm  .app-header-menu ul li{
    padding: 8px;
}


.app-header-title{
    color:white;
    float: left;
    padding-left: 20px;
}
.app-header-menu{
    float: right;
    color:white;
    text-align: left;
    padding-right: 20px;
}

.app-header-menu li{
    list-style: none;
}
.app-header-menu ul li{
    display: inline;
    padding: 15px;
    
}
.app-header-menu ul li a,.navbar-light .navbar-nav .nav-link,.navbar-brand{
    color:white !important;    
} */

.scroll-app-box{
    overflow-y: auto;
    height: 60vh;
}