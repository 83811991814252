.erport-actions {
  float: right;
  color: white;
  text-align: left;
  padding-right: 20px;
}

.erport-actions li {
  list-style: none;
}
.erport-actions ul li {
  display: inline;
  padding: 15px;
}
.erport-actions ul li a {
  color: #b5539d;
}
.icon-view {
  color: #00695f;
}
.icon-view svg {
  margin: 0 auto !important;
}
.titleSubGrup span {
  color: #01b5af;
  text-transform: uppercase;
}

.div-button-right {
  width: 100%;
  text-align: right;
}
/* Estilo para las columnas */
.title-column-evaluation {
  padding: 0;
  background-color: #01b5af;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  border: 2px solid white;
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Opcional: centra horizontalmente */
  height: 50px; /* Coincide con las filas de contenido */
  box-sizing: border-box;
}

.question-column,
.response-column,
.qppv-column {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: left; /* Centra horizontalmente */
  height: 50px; /* Asegura una altura consistente */
  padding: 0; /* Elimina márgenes y rellenos */
  box-sizing: border-box;
}

.actions-load-m {
  margin-right: 50px !important;
}

@media only screen and (min-width: 1px) and (max-width: 740px) {
  .description-hide {
    display: none !important;
  }
  .icon-menu-expand {
    font-size: 50px !important;
  }
}

.report-cancel .sectionTitle {
  background: #808080;
  border-color: #808080;
}
.report-cancel .summarySection {
  border-color: #808080;
  background-color: #80808033;
}
.titleSubGrup.report-cancel span {
  color: #808080;
}

.summary-modal-body.summary-details .form-step {
  margin: 20px;
  border: 1px solid #018682;
}
.summary-modal-body.summary-details .form-step h3 {
  border: 1px solid #018682;
  background-color: #018682;
  min-height: 36px;
  color: #fff;
  font-size: 1.5rem;
}
