.card-actions div {
  padding: 0;
  width: 100%;
  text-align: right;
}

.card-actions .card-actions-button {
  width: 10%;
}

.step {
  font-size: 1.2em !important;
  background-color: #e8e8e8 !important;
  color: #000 !important;
  font-weight: normal !important;
  padding: 0.68em 0.76em !important;
  border-radius: 15% !important;
  border: #e8e8e8 3px solid !important;
}

.step.step-success {
  background-color: #01b5af !important;
  color: #fff !important;
  border: #00695f 2.5px solid !important;
  font-weight: bold !important;
}

.step.step-primary {
  background-color: #01b5af !important;
  color: #fff !important;
  border: #01b5af 3px solid !important;
  font-weight: bold !important;
}
.step-label {
  background-color: transparent !important;
  margin-top: -10px !important;
}
.step-label.step-label-success {
  color: #00695f !important;
}
.step-label.step-label-primary {
  color: #01b5af !important;
}
.no-scroll {
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
}
