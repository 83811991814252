@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500,600,800');

.home-cim {
    scroll-behavior: smooth;
    font-family: "Montserrat", sans-serif;
    width: 100% !important;
}

.home-cim div.content-wrap {
    width: 100%;
    padding: 70px 10%;
    margin: 0px auto;
    display: block;
    max-width: 1200px;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.full-page-wrap {
    width: 100vw;
    height: 100vh;
    padding: 40px 8%;
    margin: 0px;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.full-page-wrap#inicio {
    padding: 0px
}

.home-cim div.div-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.div-33 {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.div-25 {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim p {
    font-size: 1em;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    color: #333;
    text-align: center;
    display: block;
    max-width: 840px;
    margin: 1em auto
}

.home-cim p.left-align {
    text-align: left
}

.home-cim a,
.home-cim a:link,
.home-cim a:visited {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: #B64C92
}

.home-cim a.button {
    border: 1px solid #FFF;
    padding: 10px 14px 11px;
    text-decoration: none;
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}

.home-cim .mobile-only {
    display: none
}

ul.main-menu {
    width: 725px;
    margin: 0px;
    position: absolute;
    top: 26px;
    right: 50px;
    z-index: 15;
    text-align: right
}

.home-cim ul.main-menu li {
    list-style-type: none;
    display: inline-block;
    color: #333;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 11px 8px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px
}

/* .top-menu-wrap:hover{
    background-color:#ffffff33
} */
.actions-wrap-main {
    /* background: none !important; */
    padding: 40px 2% 150px !important;
    max-width: 100% !important;
    position: relative;
}

.report-main-wrap {
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

button.login-btn {
    width: 150px;
    height: 40px;

    color: #fff;
    border-radius: 40px;
    background-color: transparent;
    border-color: #fff;
    outline: none;
}

.login-form button.login-btn.inverse {
    background-color: #01b5af;
    color: #fff;
    border-color: transparent;
}

.login-form input:focus {
    outline: #01b5af;
    border-color: #01b5af;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    color: #01b5af;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}

.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: transparent;
    border-color: #01b5af;
    outline: none;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    border-color: #fff;
    outline: none;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .25);
}

.home-cim ul.main-menu li:last-child {
    margin-right: 0px;
    margin-left: 5px;
    border: 1px solid #333
}

.home-cim ul.main-menu li a,
.home-cim ul.main-menu li a:link,
.home-cim ul.main-menu li a:visited {
    color: #333;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none
}

.home-cim ul.main-menu li.active-nav a,
.home-cim ul.main-menu li.active-nav a:link,
.home-cim ul.main-menu li.active-nav a:visited {
    background: #fff;
    border: 4.5px solid #B64C92
}

.home-cim ul.main-menu.white-menu {
    top: 43px
}

.home-cim ul.main-menu.white-menu li {
    color: #FFF;
    border-color: #FFF
}

.home-cim ul.main-menu.white-menu li:last-child {
    border: 1px solid #FFF
}

.home-cim ul.main-menu.white-menu li:last-child:hover {
    background: #FFF;
    color: #50AAA9;
    cursor: pointer;
}

.home-cim ul.main-menu.white-menu li a,
.home-cim ul.main-menu.white-menu li a:link,
.home-cim ul.main-menu.white-menu li a:visited {
    color: #FFF
}

.home-cim div#floating-menu {
    position: fixed;
    top: -200px;
    left: 0px;
    background: #FFF;
    z-index: 20;
    width: 100%;
    padding: 20px 30px;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div#floating-menu.show-menu {
    top: 0px
}

div#floating-menu img {
    width: 100px
}

.home-cim div.mobile-bt {
    display: none
}

.home-cim div.mobile-menu-wrap {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 177, 169, 0.9);
    padding: 90px 10%;
    display: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim ul.mobile-menu-nav {
    margin: 0px auto;
    display: block;
    width: 100%;
    padding: 0px;
    max-width: 280px
}

ul.mobile-menu-nav li {
    list-style-type: none;
    padding: 14px 0px;
    text-align: center;
    border-bottom: 1px solid #FFF;
    color: #FFF;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 300
}

ul.mobile-menu-nav li:last-child {
    border-bottom: none
}

ul.mobile-menu-nav li a,
ul.mobile-menu-nav li a:link,
ul.mobile-menu-nav li a:link:visited {
    text-decoration: none;
    color: #FFF;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 300
}

ul.mobile-menu-nav li i {
    font-size: 14px
}

ul.menu-lateral {
    position: fixed;
    width: 1px;
    border-left: 1px solid #c4c4c4;
    height: 190px;
    right: 60px;
    top: 50%;
    margin-top: -135px;
    padding: 0px;
    z-index: 20
}

ul.menu-lateral li {
    width: 16px;
    height: 16px;
    background: #efefef;
    list-style-type: none;
    position: relative;
    display: block;
    margin: 0px 0px 20px -8px;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

}

ul.menu-lateral li:not(:last-child):hover {
    background: #fff;
    border: 5px solid #B64C92;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

ul.menu-lateral li.active-nav,
ul.menu-lateral li.active-nav:hover {
    margin: 0px 0px 20px -10px;
    width: 20px;
    height: 20px;
    background: #B64C92;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}


ul.menu-lateral li span {
    position: absolute;
    display: none;
    top: -5px;
    right: 30px;
    width: 120px;
    background: #B64C92;
    color: #FFF;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    padding: 8px 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px
}

ul.menu-lateral li.faqs-lateral span {
    top: -13px
}

ul.menu-lateral li:last-child {
    margin: 0px 0px 0px -8px;
    background: none;
    color: #B64C92
}

ul.menu-lateral li:last-child:hover {
    font-size: 22px;
    margin: -5px 0px 20px -11px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

ul.menu-lateral li .siw {
    width: 20px;
    height: 20px;
    z-index: 10;
}

div.div-arrow-right {
    position: absolute;
    right: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid #B64C92;
    top: 50%;
    margin-top: -6px
}

div.carrousel-content {
    position: relative;
    z-index: 1;
    margin-top: 8%;
    width: 100%;
    max-width: 800px;
    padding: 0px 8%;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

div.carrousel-content hr {
    width: 80px;
    height: 10px;
    background: #FFF;
    border: none;
    margin-top: 30px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2)
}

div.carrousel-img-wrap {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    background-size: cover;
    z-index: 0;
    background: #50AAA9
}

div.carrousel-img-wrap div {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    opacity: 1
}

div.carrousel-img-wrap div.slide-1 {
    background: url("../../images/bkg-1-dsk.jpg") center center no-repeat
}

div.carrousel-img-wrap div.slide-2 {
    background: url("../../images/bkg-2-dsk.jpg") center center no-repeat
}

div.carrousel-img-wrap div.slide-3 {
    background: url("../../images/bkg-3-dsk.jpg") center center no-repeat
}

div.carrousel-img-wrap div.slide-4 {
    background: url("../../images/bkg-4-dsk.jpg") center center no-repeat
}

div.top-menu-wrap {
    width: 100%;
    position: relative;
    padding: 30px 40px;
    z-index: 5;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color 400ms linear;
    -ms-transition: background-color 400ms linear;
    transition: background-color 400ms linear
}

h1 {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    color: #333;
    font-size: 2em;
    margin: 1em 0px;
    z-index: 1
}

h1.main-header {
    text-transform: uppercase;
    font-size: 4.2em;
    color: #FFF;
    text-align: left;
    line-height: 0.94em;
    margin: 0px;
    text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2)
}

img#main-logo {
    position: relative;
    width: 140px
}

.home-arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-size: 20px;
    margin-left: -10px;

    z-index: 5;
    cursor: pointer
}

.home-arrow.arrow-1 {
    color: #FFF !important;
}

.home-arrow.arrow-2 {
    color: rgba(255, 255, 255, 0.445);
}

.home-arrow.arrow-3 {
    color: rgba(0, 0, 0, 0.322) !important;
}

div.main-content {
    width: 100%;
    max-width: 800px;
    margin: 50px 0px 10px;
    background: #FFF;
    border-left: 5px solid #B64C92;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    position: relative;
    -webkit-box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2)
}

div.main-content div.main-c-left {
    display: inline-block;
    margin: 0px;
    vertical-align: top;
    color: #333;
    text-align: left;
    font-size: 17px;
    padding: 20px 30px;
    width: 70%;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

div.main-content div.main-c-right {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 0px;
    width: 30%;
    height: 100%;
    text-align: center;
    background: #B64C92;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer
}

.home-cim div.main-content div.main-c-right a,
.home-cim div.main-content div.main-c-right a:link {
    color: #FFF;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    margin-top: -15px
}

.home-cim div.main-content div.quienes-somos-home {
    padding: 20px 30px;
    width: 60%;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.main-content div.quienes-somos-home a {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase
}

.home-cim div.main-content.linked-content {
    max-width: 500px;
    margin: 70px 0px 10px
}

.home-cim div.main-content.linked-content div.main-c-right {
    width: 40%
}

.home-cim div.main-content.classic-mode {
    background: none;
    border-left: none;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 0px transparent;
    -moz-box-shadow: 0px 0px 0px 0px transparent;
    box-shadow: 0px 0px 0px 0px transparent
}

.home-cim div.main-content.classic-mode a.action-button {
    display: inline-block;
    width: 185px;
    margin: 0px 30px 0px 0px;
    cursor: pointer;
    text-align: center
}

.home-cim div.main-content.classic-mode a.action-button i {
    margin-right: 10px
}

div.main-content span {
    color: #B64C92;
    font-weight: bold
}

.home-cim p.objetivos-stit {
    margin: 30px auto;
    font-size: 18px
}

ul.objetivos {
    width: 100%;
    max-width: 900px;
    margin: 0px auto;
    display: block;
    padding: 0px
}

.home-cim ul.objetivos li {
    list-style-type: none;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding: 10px 40px 10px 70px;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

ul.objetivos li p {
    display: inline-block;
    text-align: left
}

.home-cim div.icono-objetivo {
    width: 55px;
    height: 55px;
    color: #808080;
    padding-top: 12px;
    text-align: center;
    border: 1px solid #808080;
    font-size: 25px;
    position: absolute;
    top: 18px;
    left: 0px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.actions-wrap {
    background: url("../../images/bkg-reportes-dsk.jpg") center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 110px 10% 150px !important;
    max-width: 100% !important;
    position: relative
}

.home-cim div.actions-wrap-main p,
div.actions-wrap p {
    color: #FFF
}

.home-cim div.actions-wrap-main p.actions-tit,
.home-cim div.actions-wrap p.actions-tit {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 50px
}

.home-cim div.actions-wrap-main p.action-stit,
.home-cim div.actions-wrap p.action-stit {
    font-size: 20px !important;
    font-weight: bold !important
}

.home-cim div.actions-wrap-main div.div-50,
.home-cim div.actions-wrap div.div-50 {
    padding: 5px 40px 30px;
    text-align: center
}

.home-cim div.actions-wrap-main div.div-50 p,
.home-cim div.actions-wrap div.div-50 p {
    font-weight: 300;
    font-size: 14px;
    max-width: 290px;
    display: block;
    margin: 20px auto
}

.home-cim div.dotted-side {
    background-image: linear-gradient(#fff 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 8px;
    background-repeat: repeat-y
}

a.action-button {
    font-weight: bold;
    border: none;
    background: #FFF;
    color: #50AAA9;
    display: block;
    width: 185px;
    margin: 30px auto 0px;
    cursor: pointer
}

a.action-button i {
    margin-right: 10px
}

div.report-wrap {
    width: 80%;
    max-width: 500px;
    display: block;
    margin: 20px auto
}

div.report-wrap .action-button {
    text-align: center
}

.home-cim div.separator {
    display: block;
    margin: 0px auto;
    width: 130px;
    text-align: center
}

.home-cim div.separator hr {
    border: none;
    background: #50AAA9;
    margin: 15px 0px;
    width: 45px;
    height: 1px;
    display: inline-block;
    vertical-align: middle
}

div.separator div.sq-s {
    width: 7px;
    height: 7px;
    border: 1px solid #B64C92;
    display: inline-block;
    margin: 0px 8px;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.home-cim div.img-separator {
    width: 100%;
    height: 80vh;
    background: url("../../images/bkg-3-dsk.jpg") center center no-repeat;
    background-size: cover;
    background-attachment: fixed
}

.home-cim div.compania-sep {
    background: url("../../images/bkg-4-dsk.jpg") center center no-repeat;
    background-size: cover;
    background-attachment: fixed
}

.home-cim div.cover-wrap {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    display: none
}

.home-cim div.modal-wrap {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: 0px auto;
    margin-top: 150px;
    background: #FFF;
    padding: 22px 25px;
    text-align: center;
    display: block;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.modal-wrap p {
    color: #333;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px
}

.home-cim div.modal-wrap p.modal-tit {
    font-size: 17px;
    font-weight: bold
}

.home-cim div.modal-wrap p.modal-q {
    font-weight: bold;
    font-size: 16px
}

.home-cim div.modal-wrap p.modal-d {
    font-size: 12px
}

.home-cim div.modal-wrap p.modal-d a,
.home-cim div.modal-wrap p.modal-d a:link,
.home-cim div.modal-wrap p.modal-d a:link:visited {
    color: #50AAA9
}

.home-cim div.modal-wrap a.round-button {
    color: #50AAA9;
    border: 2px solid #50AAA9;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    width: 64px;
    height: 64px;
    font-weight: bold;
    padding-top: 16px;
    vertical-align: top;
    margin: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.modal-wrap hr {
    border: none;
    height: 1px;
    background: #999;
    margin: 20px auto 10px
}

ul.company-list {
    display: inline-block;
    width: 28%;
    vertical-align: top;
    margin: 12px 2.5%;
    padding: 0px
}

.home-cim ul.company-list li {
    color: #333;
    font-size: 14px;
    margin-bottom: 2px
}

.home-cim ul.company-list li.company-country {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 4px;
    color: #333;
    list-style-type: none
}

.home-cim div.footer-wrap {
    width: 100%;
    padding: 45px 6% 40px;
    background: #151e1d;
    border-top: 7px solid #50AAA9;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim img.logo-footer {
    width: 100%;
    height: auto;
    padding: 5px;
}

ul.footer-list {
    margin: 0px;
    padding: 0px;
    width: 100%
}

.home-cim ul.footer-list li {
    list-style-type: none;
    font-family: "Montserrat", sans-serif;
    font-size: 12px !important;
    font-weight: 300;
    color: #FFF;
    margin-bottom: 3px
}

.home-cim ul.footer-list li a {
    font-size: 12px !important;
    font-weight: 300;
    color: #FFF;
    cursor: pointer
}

.home-cim div.login-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 80;
    padding: 30px 6%;
    background: rgba(0, 177, 169, 0.9);
    display: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.home-cim div.login-content {
    text-align: center;
    width: 330px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -165px;
    margin-top: -200px
}

.home-cim div.login-content p {
    font-style: 18px;
    color: #FFF;
    text-align: center;
    font-family: "Montserrat", sans-serif
}

.home-cim div.login-content a,
.home-cim div.login-content a:link,
.home-cim div.login-content a:link:visited {
    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 13px
}

div.login-content a.login-bt,
div.login-content a:link.login-bt,
div.login-content a:link:visited.login-bt {
    display: block;
    width: 95px;
    margin: 30px auto;
    font-size: 15px;
    border: 1px solid #FFF
}

div.login-content img {
    width: 160px;
    display: block;
    margin: 0px auto 40px
}

form#login-frm {
    width: 100%;
    margin: 0px 0px 15px;
    padding: 0px
}

form#login-frm input {
    width: 100%;
    border: 2px solid #FFF;
    background: #FFF;
    font-family: "Montserrat", sans-serif;
    color: #333;
    padding: 6px 10px 7px;
    margin: 5px 0px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px
}

div#close-login {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer
}

div#close-login i {
    font-size: 40px;
    color: #FFF;
    cursor: pointer
}

ul.faqs {
    padding: 0px;
    width: 100%;
    max-width: 800px;
    display: block;
    margin: 30px auto 60px
}

ul.faqs li {
    list-style-type: none;
    width: 100%;
    text-align: center
}

ul.faqs li a.faq-q {
    font-weight: bold;
    color: #50AAA9;
    display: block;
    margin-bottom: 15px;
    cursor: pointer
}

ul.faqs li p {
    font-size: 15px;
    max-height: 0px;
    overflow: hidden;
    margin: 0px
}

.home-cim ul.faqs li p.fake-list {
    display: block;
    max-width: 600px;
    text-align: left;
    margin: 0px auto
}

.home-cim div.report-carrousel {
    padding: 20px 2px !important;
    z-index: 10;
    width: 500px;
    position: absolute;
    top: 200px;
    right: 200px;
    background: rgba(0, 0, 0, 0.46);
    border-radius: 6px;
}

/* div.actions-wrap-main p:hover{
    background-color: rgba(0, 0, 0, 0.46);
    border-radius: 5px;
    text-shadow: none;
} */

.home-cim div.report-carrousel p.action-text {
    font-size: 1.2em;
    color: #FFF;
    /* text-shadow: -1px 2px 2px rgba(0,0,0,1); */
}

/* 
h1.main-header, div.report-carrousel p.action-stit{
    text-shadow: -1px 2px 2px rgba(0,0,0,0.5);  
}*/
.home-cim div.report-carrousel .button.action-button {
    color: #50AAA9;
    background: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    border: 1px solid #FFF;
    box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.3);
    /* box-shadow: -1px 4px 2px rgba(0,0,0,0.3);  */
}

/* div.report-carrousel .button.action-button:hover
{
    font-size: 17.5px;
    margin-top:28px;
    width: 200px;
    -webkit-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease;
}  */
.home-cim div.report-carrousel .button.action-button:active {
    /* color:#fff;
    background: #01b5af;
    border: 1px solid #01b5af; */
    box-shadow: inset 0 0 20px
}

.home-cim .arrow-home {
    position: absolute;
    left: 50%;
    bottom: 0;
}

.home-cim .arrow-home .arrow-1 {
    animation: MoveUpDown-1 1s linear infinite;
}

.home-cim .arrow-home .arrow-2 {
    animation: MoveUpDown-2 1 linear infinite;
}

.home-cim .arrow-home .arrow-3 {
    animation: MoveUpDown-3 1s linear infinite;
}

@keyframes MoveUpDown-1 {

    0%,
    100% {
        bottom: 0;
    }

    50% {
        bottom: 20px;
    }
}

@keyframes MoveUpDown-2 {

    0%,
    100% {
        bottom: 5px;
    }

    50% {
        bottom: 20px;
    }
}

@keyframes MoveUpDown-3 {

    0%,
    100% {
        bottom: 3px;
    }

    50% {
        bottom: 20px;
    }
}

.home-cim div.carrousel-content {
    margin-top: 0;
    padding: 0 50px !important
}

.home-cim div.report-carrousel {
    top: 160px;
    right: 120px;
    box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
}

.home-cim h1.main-header {
    text-transform: uppercase;
    font-size: 5em;
    font-weight: 600;
    color: #FFF;
    text-align: left;
    line-height: 0.94em;
    margin: 0px;
    padding-top: 40px;
    width: 90%;
    text-shadow: -2px 5px 5px rgba(0, 0, 0, 0.6);
}

.home-cim .top-menu-wrap.top-menu-wrap-solid {
    z-index: 12;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    padding-top: 0;
    padding-bottom: 0;
    height: 58px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.home-cim .top-menu-wrap.top-menu-wrap-solid .logoCim {
    max-width: 100px !important;
    height: auto;
    top: 8px;
}

.home-cim .top-menu-wrap.top-menu-wrap-solid ul {
    top: 10px !important;
}

.home-cim div.top-menu-wrap {
    padding: 15px 40px;
}

.home-cim ul.main-menu.white-menu {
    top: 25px;
}

.home-cim a.close-modal-home,
.home-cim a.close-modal-home:visited {
    float: right;
    color: #a4a4a4;
}

.home-cim a.close-modal-home:hover {
    color: #444444;
}

.home-cim div.cover-wrap.show {
    display: block;
}

.home-cim div.modal-wrap a.round-button:hover {
    color: #fff;
    border: 2px solid #50AAA9;
    background: #50AAA9;
}


/* .top-menu-wrap.top-menu-wrap-solid a {
    color:#50AAA9;
}
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li a{
    color:#50AAA9;
}
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li:last-child{
    border:1px solid #50AAA9;
    color: #50AAA9;
} 
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li:last-child:hover{
    background: #50AAA9;
    color: #fff;
    cursor: pointer;
} */



/* FORM */

.home-cim div.modal-wrap.form {
    position: relative;
    width: 100%;
    max-width: 80%;
    margin: 0px auto;
    margin-top: 10px;
    background: #FFF;
    padding: 0;
    text-align: center;
    display: block;
    border-radius: 0px 40px 5px 5px;
    box-sizing: border-box;
    height: 97vh;

    /* background: #3e8483; */
    background: #50AAA9;
}

.home-cim div.modal-wrap.form input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin: 0px 5px 10px 0px;
}


.home-cim div.modal-wrap.form label,
.home-cim div.modal-wrap.form span,
.home-cim div.modal-wrap.form h3,
.home-cim div.modal-wrap.form h6 {
    color: #fff;
}

.home-cim div.modal-wrap.form .from-footer small {
    color: #fff !important;
}

.home-cim div.modal-wrap.form .dropdown-item span,
.home-cim div.modal-wrap.form input {
    color: #333 !important;
}

.home-cim div.modal-wrap.form .dropdown-menu a {
    color: #aaa !important;
}

.home-cim div.form .form-title {
    width: 100%;
    text-align: left;
    padding: 25px 25px;
    background: #B64C92;
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topright: 30px;
    border-top-right-radius: 30px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.home-cim div.form .form-title h2 {
    color: #fff;
}

.home-cim div.modal-wrap.form {
    text-align: left;
}

.home-cim div.form .form-step {
    padding: 20px 50px;
    overflow-y: auto;
    height: 70vh;
}

.home-cim div.form .from-footer {
    margin: 0 auto;
    width: 80%;
    text-align: center;
}

.home-cim a.close-modal-home,
.home-cim a.close-modal-home.close-form:visited {
    float: right;
    color: #e0e0e0;
}

.home-cim a.close-modal-home.close-form:hover {
    color: #d0d0d0;
}

/* .home-cim div.form .form-step .step1{
    margin: 0 auto;
    width: 60%;
} */


.home-cim .form-summary {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #FFF;
    margin: 10px 10px;

}

.home-cim .form-summary .form-step * {
    color: #333 !important;
}

.home-cim .form-summary .form-step {
    height: auto !important;
}

.home-cim .form-finished {
    margin: 30px auto;
    width: 80%;
    text-align: center;
}

.home-cim .form-finished * {
    color: #fff !important;
}

.home-cim .form-finished p.finished {
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 400;
}

.home-cim .form-finished p.finished .identificador {
    font-size: 24px !important;
}

.home-cim .form-finished a {
    color: #B64C92 !important;
    font-size: 24px !important;
}

.home-cim .form-finished a:hover {
    text-decoration: underline;

}


@media screen and (max-width: 760px) {
    .home-cim div.report-carrousel {
        top: 45%;
        right: auto;
        box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
    }

    .home-cim h1.main-header {
        text-transform: uppercase;
        font-size: 2.5em;
    }
    
    .carousel .slide img {
        width: 100%;
        vertical-align: top;
        border: 0;
        height: 100%;
    }

    .home-cim div.form .from-footer {
      display: inline;
    }

    .home-cim div.form .from-footer .login-btn {
       font-size: 10px !important;
    }

    .login-close{
        z-index: 1000;
    }

    .home-cim .modal-wrap {
        overflow-y: auto;
    }

    .home-cim div.modal-wrap.form {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0px auto;
        margin-top: 10px;
        background: #FFF;
        padding: 0;
        text-align: center;
        display: block;
        border-radius: 0px 40px 5px 5px;
        box-sizing: border-box;
        height: 97vh;
        /* background: #3e8483; */
        background: #50AAA9;
    }
}

.g-recaptcha {
    transform:scale(0.2);
    -webkit-transform:scale(0.2);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    }
