.btn-more{
    background-color:transparent !important;
    color: #3a3a3a !important;
    border: 1px solid #e7eaec !important;
}

.list-component{
    width: 100%;
    margin: 0 auto;
}

.list-component .list-table{
    border: 1px solid #01b5af;
    text-align: center;
    width: 100%;
}
.list-component .list-table-head{
    background-color: #efefef;
    border: 1px solid #01b5af;
}
.list-component .list-table  .list-table-head th{
    height: 40px;
    padding-right: 5px;
    border-bottom: 1px solid #01b5af;
    background-color: #efefef;
    font-weight: normal;
    text-align: center;
    padding-left: 5px;
}
.list-actions li {
    display: inline;
    text-decoration: none;
    margin-left: 10px;
}
.border-bottom{
    border-bottom: 1px solid #9fb5b4;
}
.list-item a{
    color:#1a746b;

}
.td-list-actions{
    width: 12%;
}
.list-actions li a{
    color:#c74caa;
}
.list-actions li a:hover{
    color: #782564;
}
.list-item td{
    padding: 0 10px;

}

.list-table-mini {
    width: 100%;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    border: none;
}

.listload-backdload::after{
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 2px;
    left: 23px; 
    right: 23px;
    bottom: 23px;
    z-index: 81;
}
.listload-load{
    display: none !important;
}
.listload-backdload .listload-load{
    display: block !important;
    position: absolute;
    top: 40%;
    left: 45%;
    right: 0;
    z-index: 82;
}

.ul-table{
    list-style: none;
    display: table;
    table-layout: fixed;
    border: 1px solid #01b5af;
    padding: 0;

}
.li-table{
    display: table-row;
}

.li-cell-table{
    display: table-cell;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    height: 40px;
    border-bottom: 1px solid #dee2e6!important;
    
}
.li-cell-head-table{
    border-bottom: 1px solid #01b5af;
    background-color: #efefef;

}
.li-row-table.row-3 .li-cell-table{
    width: calc(800px / 3);
}
.li-row-table.row-4 .li-cell-table{
    width: calc(800px / 4);
}
.li-row-table.row-5 .li-cell-table{
    width: calc(800px / 4) 
}
.li-row-table.row-6 .li-cell-table{
    width: calc(800px / 6);
}