.with-font {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.with-font + svg {
  display: inline-block;
  letter-spacing: 10px;
  font-size: 4rem;
  color: #535353;
  width: 1.4em;
  cursor: pointer;
}
.with-font:checked + svg {
  color: #01b5af;
  letter-spacing: 5px;
}
.with-font:hover + svg {
  color: #00695f;
  letter-spacing: 5px;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.w100 {
  width: 100% !important;
  margin-right: 15px !important;
}
.rdatez {
  position: relative;
  display: inline-block;
  width: 100%;
}
.strong {
  font-weight: bold;
}

.progress-textarea {
  margin-top: 5px;
  width: 200px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border-width: 2px;
}

.hiddenLabel label {
  display: none;
}
