.block{
    display: block !important;
}
.inline{
    display: inline !important;
}
.w50{
    width: 50%;
    display: inline-block;
}
.w100{
    width: 100%;
    display: inline-block;
}
.background-fff{
    background-color: #fff;
}

.background-f4f4f4{
    background-color: #f4f4f4;
}
.pull-right{
    float: right;
}
.pull-left{
    float: left;
}
.noRow{
    margin: 0 !important;
}
.modal-lg {
    max-width: 85% !important;
}


.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.hiden {
    display: none ;
}

.agile-list {
    list-style: none;
    margin: 0;
}

.agile-list li {
    display: block;
    padding-bottom: 8px;
    padding-top: 5px;
    margin-bottom: 0;
    text-align: left;
    background: #FAFAFB;
    border: 1px solid #e7eaec;
    margin: 0 0 10px 0;
    padding: 10px 10px 20px 10px;
    border-radius: 2px;
}
.agile-list li.warning-element {
    border-left: 3px solid #f8ac59;
}
.agile-list li.success-element {
    border-left: 3px solid #1ab394;
}
.agile-list li.info-element {
    border-left: 3px solid #1c84c6;
}
.agile-list li.danger-element {
    border-left: 3px solid #ed5565;
}
.agile-list li.danger-element.all-danger *{
    color:#ed5565 !important;
}
.agile-list li.warning-element *{
    color:#f8ac59 !important;
}
.agile-list li.success-element *{
    color:#1ab394 !important;
}
.agile-list li.info-element *{
    color:#1c84c6 !important;
}



.agile-buttons{
    display: none;

}

.list-item:hover > .agile-buttons{
    display: initial;
   
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 15px 8px 15px;
    min-height: 35px;
    position: relative;
    clear: both;
    width: 100%;
}
.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 8px 20px 8px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    text-align: right;
}
.ibox-tools a {
    cursor: pointer;
    margin-left: 5px;
}

.ibox-heading {
    background-color: #f3f6fb;
    border-bottom: none;
}
.text-limit-50 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
}
.text-limit-100 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.text-limit-200 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.text-limit-300 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.text-limit-400 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}

.text-success {
    color: #1c84c6 !important;
}

.text-danger {
    color: #dc3545!important;
}

.font-bold {
    font-weight: 600;
}
.stat-percent {
    float: right;
}
/*MARGINS*/
.m {
    margin: 20px !important}
.m-n{
    margin: 0px !important}
.m-t-n{
    margin-top: 0px !important}
.m-t{
    margin-top: 10px !important}
.m-t-sm{
    margin-top: 5px !important}
.m-t-md{
    margin-top: 15px !important}
.m-t-25{
    margin-top: 25px !important}
.m-t-lg{
    margin-top: 30px !important}
.m-t-md-m{
    margin-top: -15px !important}
.m-t-lg-m{
    margin-top: -35px !important}
.m-b{
    margin-bottom: 10px !important}
.m-b-n{
    margin-bottom: 0px !important}
.m-b-sm{
    margin-bottom: 5px !important}
.m-b-md{
    margin-bottom: 15px !important}
.m-b-lg{
    margin-bottom: 30px !important}
.m-l{
    margin-left: 10px !important}
.m-l-n{
    margin-left: 0px !important}
.m-l-sm{
    margin-left: 5px !important}
.m-l-md{
    margin-left: 15px !important}
.m-l-lg{
    margin-left: 30px !important}
.m-l-md-m{
    margin-left: -10px !important
}

.m-r{
    margin-right: 10px !important}
.m-r-n{
    margin-right: 0px !important}
.m-r-sm{
    margin-right: 5px !important}
.m-r-md{
    margin-right: 15px !important}
.m-r-lg{
    margin-right: 30px !important}
.m-r-md-m{
    margin-right: -10px !important
}
/*PADDINGS*/
.p {
    padding:  20px !important}
.p-sm{
    padding:  5px !important}

.p-md {
        padding:  10px !important}
.p-lg {
    padding:  40px !important}
.p-n{
    padding: 0px !important}
.p-t{
    padding-top: 10px !important}
.p-t-n{
    padding-top: 0px !important}
.p-t-sm{
    padding-top: 10px !important}
.p-t-md{
    padding-top: 15px !important}
.p-t-lg{
    padding-top: 30px !important}
.p-b{
    padding-bottom: 20px !important}
.p-b-n{
    padding-bottom: 0px !important}
.p-b-sm{
    padding-bottom: 5px !important}
.p-b-md{
    padding-bottom: 15px !important}
.p-b-lg{
    padding-bottom: 30px !important}
.p-l-n{
    padding-left: 0px !important}
.p-l{
    padding-left: 20px !important}
.p-l-sm{
    padding-left: 5px !important}
.p-l-md{
    padding-left: 15px !important}
.p-l-lg{
    padding-left: 30px !important}

.p-r-n{
    padding-right: 0px !important}
.p-r{
    padding-right: 20px !important}
.p-r-sm{
    padding-right: 5px !important}
.p-r-md{
    padding-right: 15px !important}
.p-r-lg{
    padding-right: 30px !important}
.b-n{
    border:none !important}
.b-t-n{
    border-top:none !important
}

/*BORDERS*/
.b-radius-n{
    border-radius: 0 !important;
}
.border-lr-n{
    border-left: none !important;
    border-right: none !important;
}
.border-b-n {
    border-bottom: none !important;
}
/**/

.va-m {
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
  .text-nowrap {
    white-space: nowrap;
  }
  .text-lowercase {
    text-transform: lowercase;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .text-muted {
    color: #777;
  }

  