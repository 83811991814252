@import "./tooltik.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f4f4f4 !important;
}
.react-toggle {
  touch-action: pan-x;
  display: block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 60px;
  height: 30px;
  padding: 0;
  border-radius: 30px;
  background-color: #545454;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #016764;
}

.react-toggle--checked .react-toggle-track {
  background-color: #01b5af;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #01b5af;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: 5px;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 25px;
  height: 28px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 34px;
  border-color: #01b5af;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #01b5af;
  -moz-box-shadow: 0px 0px 3px 2px #01b5af;
  box-shadow: 0px 0px 2px 3px #01b5af;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #01b5af;
  -moz-box-shadow: 0px 0px 5px 5px #01b5af;
  box-shadow: 0px 0px 5px 5px #01b5af;
}

.react-toggle.heart:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #7a211b !important;
}

.react-toggle--checked.heart .react-toggle-track {
  background-color: #f44336 !important;
}

.react-toggle--checked.heart:hover:not(.react-toggle--disabled)
  .react-toggle.heart
  .react-toggle-track {
  background-color: #f44336 !important;
}

.react-toggle--checked.heart .react-toggle-thumb {
  left: 34px;
  border-color: #f44336 !important;
}

.heart.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #f6685e !important;
  -moz-box-shadow: 0px 0px 3px 2px #f6685e !important;
  box-shadow: 0px 0px 2px 3px #f6685e !important;
}

.heart.react-toggle :active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #f6685e;
  -moz-box-shadow: 0px 0px 5px 5px #f6685e;
  box-shadow: 0px 0px 5px 5px #f6685e;
}

.sectionTitle {
  border: 1px solid #01b5af;
  background-color: #01b5af;
  min-height: 42px;
}
.sectionTitle.disabled {
  border: 1px solid gray;
  background-color: gray;
}
.sectionTitle .title {
  color: white;
  font-family: "open Sans", sans-serif;
  font-size: 17px;
  padding-left: 10px;
  font-weight: 300;
  line-height: 35px;
}

.sectionFooter {
  padding: 10px;
  border-top: 1px solid #01b5af66;
}

.summarySection {
  border: 1px solid #01b5af;
  margin-bottom: 15px;
}

.close.rbt-close {
  margin-top: -28px !important;
  margin-right: 5px !important;
  margin-left: 3px !important;
}

/* [class*="MuiCircularProgress-colorPrimary"] {
  color: #1a746b !important;
} */

.teams-config {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}
.teams-config .teams-config-selects {
  width: 70%;
  margin: 0 auto;
}
.teams-config .teams-config-selects .teams-config-input {
  padding: 10px;
  font-size: 20px;
}
.teams-config .teams-config-selects .teams-config-input input {
  margin: 5px;
}

.text-success {
  color: #01b5af !important;
}

.flip-card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.show-back .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  z-index: 2;
  transform: rotateY(0deg);
}

.flip-card-back {
  z-index: 1;
  transform: rotateY(180deg);
}
.background-color-01b5af {
  background-color: #01b5af !important;
}
