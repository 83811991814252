$color: #1ab394;
.ark-table {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 0;
    border: 1px solid #ddd;
    // display: flex;
    min-width: 100%;
    margin-bottom: 1.25rem;
    color: #333;
    border-collapse: collapse;
}

.ark-table:last-child {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem;
}

.ark-thead{
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.ark-table td{
    padding: .15rem 1.25rem;
    vertical-align: middle;
    border-top: 1px solid #ddd;
}
.ark-table tr {
    transition: background-color ease-in-out .15s;
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.ark-table-filters:hover input{
    display:initial !important;
}
.ark-table-filters input{
    display: none !important;
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='-300 -10 1000 500'  fill-rule='evenodd'%3E%3Cpath fill='rgba(0, 0, 0,0.2)' d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'%3E%3C/path%3E%3C/svg%3E") right / contain no-repeat ;
}
.ark-table-filters input:focus {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='-300 -10 1000 500'  fill-rule='evenodd'%3E%3Cpath fill='rgba(26, 179, 148,0.6)' d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'%3E%3C/path%3E%3C/svg%3E") right / contain no-repeat ;
}


