.signature-modal .modal-header{
    background-color: #01b5af !important;
    color: white;
}
.signature-modal .modal-content{
    border-color: #01b5af !important;
    border-width: 2px;
}

.signature-modal-error  .modal-header{
    background-color: #dc3545 !important;
    color: white;
}
.signature-modal-error .modal-content{
    border-color: #dc3545 !important;
    border-width: 2px;
}

/* .from-signature input:-webkit-autofill{
    display:none !important;
} */
