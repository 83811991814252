.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.button-next {
  background-color: #01b5af !important;
  color: white !important;
}

.button-next:disabled {
  background-color: #538786 !important;
}

.background-login {
  background-color: rgb(1, 181, 175);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  z-index: 1;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.background-login.lock {
  background-color: rgba(1, 181, 175, 1);
  z-index: 8887;
  transform: translateY(-100%);
  transition: transform 0.6s ease-out;
}

.background-login.lock.open {
  transform: translateY(0);
}

.login-form {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  top: 40px !important;
}

@media only screen and (min-width: 1px) and (max-width: 600px) {
  .background-img {
    background-position: center;
    background-size: 280%;
    z-index: 1;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0vh;
    left: 0vw;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .background-img {
    background-position: center;
    background-size: 150%;
    z-index: 1;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0vh;
    left: 0vw;
  }
}

.collapse.show.navbar-collapse {
  background-color: #00695f !important;
}
.collapse.show.navbar-collapse ul {
  float: left;
  padding-left: 20px;
}
.collapse.show.navbar-collapse .dropdown-menu {
  background-color: #00695f !important;
}

.summary-modal .modal-content {
  margin-top: -25px !important;
}
.summary-modal-body {
  height: 73vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.summary-modal-body .form-summary .form-step {
  border: 1px solid #01b5af;
  margin-bottom: 20px !important;
}

.summary-modal-body .form-summary .form-step .form-step-row {
  padding: 0px 20px !important;
}
.summary-modal-body .form-summary h3 {
  border: 1px solid #01b5af;
  background-color: #01b5af;
  min-height: 42px;
  color: #fff;
  padding: 3px 5px;
}
.summary-modal-body .form-summary h2 {
  display: none;
}
.noborder {
  border: none !important;
}

.captcha-adjust {
  margin-right: 10vh;
}
