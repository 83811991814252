.upload-load{
    display: grid;
    grid-template-columns: 65px auto auto 95px;
    border: 1px solid #01b5af;
}

.upload-load div{
    padding: 20px 0;
}
.upload-load.view div{
    padding: 5px 10px;
}


.upload-load .file-accepted-load{
    color:#00695f;
    grid-column-start: 1;
    grid-column-end: 1;
    padding-left: 15px;
    background-color: #f4f4f4;
}

.upload-load .file-name-load{
    grid-column-start: 2;
    grid-column-end: 4;
    padding-left: 15px;
    font-weight: bold;
    line-height: 35px;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

.upload-load .file-action{
    grid-column-start: 4;
    grid-column-end: 4;
    padding-left: 15px;
    padding-top: 30px;
}

.upload-load.view .file-action{
    padding-top: 15px;
}

.my-dropzone{
    border: 1px solid rgba(#000, 0.1);
    width: 100%;
    height: 100px;
    display: block;
    border: 1px solid #01b5af;
    box-sizing: border-box;
    
    background-image: linear-gradient(135deg,rgba(0,0,0,.03)25%, transparent 25%, transparent 50%, rgba(0,0,0,.03)50%, rgba(0,0,0,.03)75%, transparent 75%, transparent);
    background-color: #FAFCFD;
    background-size: 16px 16px;
}
.my-dropzone p{
    padding: 35px 0;
    font-weight: bold;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

.action-delete{
    color:#dc3545;
    text-align: center;
}
.action-delete :hover{
    color: #c82333;
}

.action-download{
    color:#17a2b8;
    text-align: center;
}
.action-download :hover{
    color: #138496;
}
.action-edit{
    color:#28a745;
    text-align: center;
}
.action-edit :hover{
    color: #218838;
}