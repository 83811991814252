@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,500,600,800);
.block{
    display: block !important;
}
.inline{
    display: inline !important;
}
.w50{
    width: 50%;
    display: inline-block;
}
.w100{
    width: 100%;
    display: inline-block;
}
.background-fff{
    background-color: #fff;
}

.background-f4f4f4{
    background-color: #f4f4f4;
}
.pull-right{
    float: right;
}
.pull-left{
    float: left;
}
.noRow{
    margin: 0 !important;
}
.modal-lg {
    max-width: 85% !important;
}


.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
}

.hiden {
    display: none ;
}

.agile-list {
    list-style: none;
    margin: 0;
}

.agile-list li {
    display: block;
    padding-bottom: 8px;
    padding-top: 5px;
    margin-bottom: 0;
    text-align: left;
    background: #FAFAFB;
    border: 1px solid #e7eaec;
    margin: 0 0 10px 0;
    padding: 10px 10px 20px 10px;
    border-radius: 2px;
}
.agile-list li.warning-element {
    border-left: 3px solid #f8ac59;
}
.agile-list li.success-element {
    border-left: 3px solid #1ab394;
}
.agile-list li.info-element {
    border-left: 3px solid #1c84c6;
}
.agile-list li.danger-element {
    border-left: 3px solid #ed5565;
}
.agile-list li.danger-element.all-danger *{
    color:#ed5565 !important;
}
.agile-list li.warning-element *{
    color:#f8ac59 !important;
}
.agile-list li.success-element *{
    color:#1ab394 !important;
}
.agile-list li.info-element *{
    color:#1c84c6 !important;
}



.agile-buttons{
    display: none;

}

.list-item:hover > .agile-buttons{
    display: inline;
    display: initial;
   
}

.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    -webkit-border-image: none;
            border-image: none;
    border-style: solid solid none;
    border-width: 2px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 15px 8px 15px;
    min-height: 35px;
    position: relative;
    clear: both;
    width: 100%;
}
.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 8px 20px 8px 20px;
    border-color: #e7eaec;
    -webkit-border-image: none;
            border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}
.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0;
    text-align: right;
}
.ibox-tools a {
    cursor: pointer;
    margin-left: 5px;
}

.ibox-heading {
    background-color: #f3f6fb;
    border-bottom: none;
}
.text-limit-50 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
}
.text-limit-100 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.text-limit-200 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.text-limit-300 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.text-limit-400 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}

.text-success {
    color: #1c84c6 !important;
}

.text-danger {
    color: #dc3545!important;
}

.font-bold {
    font-weight: 600;
}
.stat-percent {
    float: right;
}
/*MARGINS*/
.m {
    margin: 20px !important}
.m-n{
    margin: 0px !important}
.m-t-n{
    margin-top: 0px !important}
.m-t{
    margin-top: 10px !important}
.m-t-sm{
    margin-top: 5px !important}
.m-t-md{
    margin-top: 15px !important}
.m-t-25{
    margin-top: 25px !important}
.m-t-lg{
    margin-top: 30px !important}
.m-t-md-m{
    margin-top: -15px !important}
.m-t-lg-m{
    margin-top: -35px !important}
.m-b{
    margin-bottom: 10px !important}
.m-b-n{
    margin-bottom: 0px !important}
.m-b-sm{
    margin-bottom: 5px !important}
.m-b-md{
    margin-bottom: 15px !important}
.m-b-lg{
    margin-bottom: 30px !important}
.m-l{
    margin-left: 10px !important}
.m-l-n{
    margin-left: 0px !important}
.m-l-sm{
    margin-left: 5px !important}
.m-l-md{
    margin-left: 15px !important}
.m-l-lg{
    margin-left: 30px !important}
.m-l-md-m{
    margin-left: -10px !important
}

.m-r{
    margin-right: 10px !important}
.m-r-n{
    margin-right: 0px !important}
.m-r-sm{
    margin-right: 5px !important}
.m-r-md{
    margin-right: 15px !important}
.m-r-lg{
    margin-right: 30px !important}
.m-r-md-m{
    margin-right: -10px !important
}
/*PADDINGS*/
.p {
    padding:  20px !important}
.p-sm{
    padding:  5px !important}

.p-md {
        padding:  10px !important}
.p-lg {
    padding:  40px !important}
.p-n{
    padding: 0px !important}
.p-t{
    padding-top: 10px !important}
.p-t-n{
    padding-top: 0px !important}
.p-t-sm{
    padding-top: 10px !important}
.p-t-md{
    padding-top: 15px !important}
.p-t-lg{
    padding-top: 30px !important}
.p-b{
    padding-bottom: 20px !important}
.p-b-n{
    padding-bottom: 0px !important}
.p-b-sm{
    padding-bottom: 5px !important}
.p-b-md{
    padding-bottom: 15px !important}
.p-b-lg{
    padding-bottom: 30px !important}
.p-l-n{
    padding-left: 0px !important}
.p-l{
    padding-left: 20px !important}
.p-l-sm{
    padding-left: 5px !important}
.p-l-md{
    padding-left: 15px !important}
.p-l-lg{
    padding-left: 30px !important}

.p-r-n{
    padding-right: 0px !important}
.p-r{
    padding-right: 20px !important}
.p-r-sm{
    padding-right: 5px !important}
.p-r-md{
    padding-right: 15px !important}
.p-r-lg{
    padding-right: 30px !important}
.b-n{
    border:none !important}
.b-t-n{
    border-top:none !important
}

/*BORDERS*/
.b-radius-n{
    border-radius: 0 !important;
}
.border-lr-n{
    border-left: none !important;
    border-right: none !important;
}
.border-b-n {
    border-bottom: none !important;
}
/**/

.va-m {
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
  .text-nowrap {
    white-space: nowrap;
  }
  .text-lowercase {
    text-transform: lowercase;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .text-muted {
    color: #777;
  }

  
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f4f4f4 !important;
}
.react-toggle {
  touch-action: pan-x;
  display: block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 60px;
  height: 30px;
  padding: 0;
  border-radius: 30px;
  background-color: #545454;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #016764;
}

.react-toggle--checked .react-toggle-track {
  background-color: #01b5af;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #01b5af;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: 5px;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 25px;
  height: 28px;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 34px;
  border-color: #01b5af;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #01b5af;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #01b5af;
}

.react-toggle.heart:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #7a211b !important;
}

.react-toggle--checked.heart .react-toggle-track {
  background-color: #f44336 !important;
}

.react-toggle--checked.heart:hover:not(.react-toggle--disabled)
  .react-toggle.heart
  .react-toggle-track {
  background-color: #f44336 !important;
}

.react-toggle--checked.heart .react-toggle-thumb {
  left: 34px;
  border-color: #f44336 !important;
}

.heart.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #f6685e !important;
}

.heart.react-toggle :active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #f6685e;
}

.sectionTitle {
  border: 1px solid #01b5af;
  background-color: #01b5af;
  min-height: 42px;
}
.sectionTitle.disabled {
  border: 1px solid gray;
  background-color: gray;
}
.sectionTitle .title {
  color: white;
  font-family: "open Sans", sans-serif;
  font-size: 17px;
  padding-left: 10px;
  font-weight: 300;
  line-height: 35px;
}

.sectionFooter {
  padding: 10px;
  border-top: 1px solid #01b5af66;
}

.summarySection {
  border: 1px solid #01b5af;
  margin-bottom: 15px;
}

.close.rbt-close {
  margin-top: -28px !important;
  margin-right: 5px !important;
  margin-left: 3px !important;
}

/* [class*="MuiCircularProgress-colorPrimary"] {
  color: #1a746b !important;
} */

.teams-config {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}
.teams-config .teams-config-selects {
  width: 70%;
  margin: 0 auto;
}
.teams-config .teams-config-selects .teams-config-input {
  padding: 10px;
  font-size: 20px;
}
.teams-config .teams-config-selects .teams-config-input input {
  margin: 5px;
}

.text-success {
  color: #01b5af !important;
}

.flip-card {
  width: 100%;
  height: 100%;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-card.show-back .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.flip-card-front {
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.flip-card-back {
  z-index: 1;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.background-color-01b5af {
  background-color: #01b5af !important;
}

.login-form {
  width: 40%;
  margin: 8% auto;
  padding: 15px;
  border-radius: 15px;
  background-color: transparent;
  text-align: center;
  text-align: -webkit-center;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-size: 1.2rem;
  color: white;
}
.login-form a,
login-form a:link {
  color: #fff !important;
}
.login-form-group {
  text-align: left !important;
}
.error {
  text-align: center;
  text-align: -webkit-center;
}
.login-form img {
  display: block;
  margin: 0 auto;
}
.inputWithIcon input {
  height: 35px !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 20px;
}
.inputWithIcon {
  margin-bottom: 0;
  padding-bottom: 0;
}
.inputWithIcon svg {
  position: relative;
  left: 20%;
  top: -30px;
  color: #aaa;
  margin-left: 5px;
  margin-top: -10px;
}
.inputWithIcon input:focus {
  border-color: #fff;
}
.inputWithIcon input:focus + svg {
  color: #fff;
}

.login-form input,
.login-form button {
  width: 60%;
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  font-size: 1rem;
}
.login-form button.login-btn {
  width: 150px;
  height: 40px;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  border-radius: 40px;
  background-color: transparent;
  border-color: #fff;
  outline: none;
}
.login-form button.login-btn.inverse {
  background-color: #01b5af;
  color: #fff;
  border-color: transparent;
}
.login-form input:focus {
  outline: #01b5af;
  border-color: #01b5af;
}
.login-close {
  color: white !important;
  font-size: 4rem;
  position: absolute;
  top: 10px;
  right: 10%;
  text-decoration: none;
}
.login-close:hover svg,
.login-close:active svg {
  color: rgba(255, 255, 255, 0.459);
}

.invalid-tooltip {
  position: relative !important;
  width: 250px !important;
  left: 22% !important;
  margin-bottom: 20px;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #01b5af;
  background-color: #fff;
  border-color: #fff;
  outline: none;
}
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: transparent;
  border-color: #01b5af;
  outline: none;
}
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  border-color: #fff;
  outline: none;
}
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
}

.forgetpass {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.forgetpass:hover,
.forgetpass:active {
  color: white;
}

.divforgetpass {
  text-align: center;
  text-align: -webkit-center;
}

.login-flip {
  background-color: rgba(1, 181, 175);
}

@media only screen and (min-width: 1px) and (max-width: 600px) {
  .login-form {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-top: 25%;
    padding-bottom: 60%;
    margin-top: 0;
  }
  .invalid-tooltip {
    position: relative !important;
    width: 250px !important;
    left: 2% !important;
  }

  .buttonProgress {
    margin-top: 80px;
  }
  .login-form-group {
    margin-top: 25%;
  }

  .login-form-group input {
    width: 100%;
    height: 45px !important;
    font-size: 18px !important;
    margin-top: 15px !important;
  }
  .inputWithIcon svg {
    position: relative;
    left: 20%;
    top: -35px;
    color: #aaa;
    margin-left: 5px;
  }
  .login-button {
    margin-top: 95px;
  }
  .img-label {
    width: 95%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .login-form {
    width: 60%;
    margin: 12% auto;
  }
}

@media only screen and (min-width: 1400px) {
  .login-form {
    width: 30%;
    margin: 12% auto;
  }
  .login-form-group input {
    width: 60%;
  }
}

.btn-more{
    background-color:transparent !important;
    color: #3a3a3a !important;
    border: 1px solid #e7eaec !important;
}

.list-component{
    width: 100%;
    margin: 0 auto;
}

.list-component .list-table{
    border: 1px solid #01b5af;
    text-align: center;
    width: 100%;
}
.list-component .list-table-head{
    background-color: #efefef;
    border: 1px solid #01b5af;
}
.list-component .list-table  .list-table-head th{
    height: 40px;
    padding-right: 5px;
    border-bottom: 1px solid #01b5af;
    background-color: #efefef;
    font-weight: normal;
    text-align: center;
    padding-left: 5px;
}
.list-actions li {
    display: inline;
    text-decoration: none;
    margin-left: 10px;
}
.border-bottom{
    border-bottom: 1px solid #9fb5b4;
}
.list-item a{
    color:#1a746b;

}
.td-list-actions{
    width: 12%;
}
.list-actions li a{
    color:#c74caa;
}
.list-actions li a:hover{
    color: #782564;
}
.list-item td{
    padding: 0 10px;

}

.list-table-mini {
    width: 100%;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    border: none;
}

.listload-backdload::after{
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 2px;
    left: 23px; 
    right: 23px;
    bottom: 23px;
    z-index: 81;
}
.listload-load{
    display: none !important;
}
.listload-backdload .listload-load{
    display: block !important;
    position: absolute;
    top: 40%;
    left: 45%;
    right: 0;
    z-index: 82;
}

.ul-table{
    list-style: none;
    display: table;
    table-layout: fixed;
    border: 1px solid #01b5af;
    padding: 0;

}
.li-table{
    display: table-row;
}

.li-cell-table{
    display: table-cell;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    height: 40px;
    border-bottom: 1px solid #dee2e6!important;
    
}
.li-cell-head-table{
    border-bottom: 1px solid #01b5af;
    background-color: #efefef;

}
.li-row-table.row-3 .li-cell-table{
    width: calc(800px / 3);
}
.li-row-table.row-4 .li-cell-table{
    width: calc(800px / 4);
}
.li-row-table.row-5 .li-cell-table{
    width: calc(800px / 4) 
}
.li-row-table.row-6 .li-cell-table{
    width: calc(800px / 6);
}
.with-font {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.with-font + svg {
  display: inline-block;
  letter-spacing: 10px;
  font-size: 4rem;
  color: #535353;
  width: 1.4em;
  cursor: pointer;
}
.with-font:checked + svg {
  color: #01b5af;
  letter-spacing: 5px;
}
.with-font:hover + svg {
  color: #00695f;
  letter-spacing: 5px;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.w100 {
  width: 100% !important;
  margin-right: 15px !important;
}
.rdatez {
  position: relative;
  display: inline-block;
  width: 100%;
}
.strong {
  font-weight: bold;
}

.progress-textarea {
  margin-top: 5px;
  width: 200px;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  border-width: 2px;
}

.hiddenLabel label {
  display: none;
}

.signature-modal .modal-header{
    background-color: #01b5af !important;
    color: white;
}
.signature-modal .modal-content{
    border-color: #01b5af !important;
    border-width: 2px;
}

.signature-modal-error  .modal-header{
    background-color: #dc3545 !important;
    color: white;
}
.signature-modal-error .modal-content{
    border-color: #dc3545 !important;
    border-width: 2px;
}

/* .from-signature input:-webkit-autofill{
    display:none !important;
} */


.ibox-content.wf-list{
    padding-bottom: 40px !important;
}

.search-box{
    position: absolute;
    top:49%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    background: #01574e;
    height: 40px;
    border-radius: 40px;
    
}

.search-box:hover > .search-text{
    width: 380px;
    padding: 0 6px;
    padding-left: 15px;
}

.search-btn{
    color:#aaa !important;
    float:right;
    width: 40px;
    height: 40px;
    border-radius:50%; 
    background-color: #01574e;
    justify-content: center !important;
    align-items: center !important;
    font-size: 18px;
    padding: .5rem .5rem !important;
}

.search-btn svg{
    margin: 2px;
}


.search-text{
    border:none;
    background: none;
    float:left;
    padding:0;
    color:white;
    font-size: 16px;
    -webkit-transform: 0.4s;
            transform: 0.4s;
    line-height: 40px;
    width: 380px; 
    padding-left: 15px;

    outline: none;
   
}

.search-text::-ms-input-placeholder, .search-text:-ms-input-placeholder, .search-text::-moz-placeholder, .search-text::-webkit-input-placeholder,  .search-text::-moz-placeholder{
    font-weight: red;
}

.search-text::placeholder, .search-text:-ms-input-placeholder, .search-text::-moz-placeholder, .search-text::-webkit-input-placeholder,  .search-text::-moz-placeholder{
    font-weight: red;
}


.search-text::-moz-selection{
    border: none;
}


.search-text::selection{
    border: none;
}

.search-result-box{
    background-color: #f1f1f1;
    width: 400px;
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,20px);
            transform: translate(-50%,20px);
    overflow-y: scroll;
    max-height: 60vh;

    border-bottom: #ddd 1px solid;
    border-radius: 5px;
}

.search-result-box.hide{
    display: none;
}
.search-result-box.show{
    display: inline;
    display: initial;

}

.search-result-box .list-table-mini{
    display: block;
    width: 100%;

}

.search-result-box .list-table-mini *{
    font-size: 14px !important;

}
.search-result-box .list-table-mini .text-muted{
    font-size: 12px !important;
}


.search-result-box .list-table-mini .list-item{
    margin-bottom: 0;
}


.app-footer{
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    background-color: #f1f1f1;
    width: 100%;
}
.appTitle{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    color: white;
    font-family: 'open Sans', sans-serif;
    margin-left: 10px;
    margin-right: 10px;
}
.app-title{
    background-color: #01b5af;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    
    top:0;
    height: 60px;
}
.app-title span{
    padding-top: 5px;
    padding-left: 10px;
    color:white;
    float: left;
    font-size: 2rem;
}

.app-title-actions{
    float: right;
    padding-top:15px;
}

.app-frame{
    padding: 10px 20px;
    margin-top: 55px;
    margin-bottom: 50px;  
}
.app-frame .card{
    background-color: rgba(255,255,255,0.9);
}
.iconLabel{
    margin-left:10px; 
}
.loader-bk{
    background-color: rgba(0,0,0,0.95);
    z-index: 8888;
    align-items: center;
    text-align: center;
    position: fixed;
    top:40%;
    left: 44.5%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;   
}
.loader-cr{
    position: fixed;
    top:40%;
    left: 46%;
    z-index: 8889;

}

.loader-bk .MuiCircularProgress-colorPrimary-74{
    color: rgba(1,181,175, 0.8);
}

.navbarTop {
    z-index: 8890 !important;
    box-shadow: 0px 0px 10px 1px #00211e;
}

.pagination .page-link{
    color:#01b5af;
    
}
.pagination .page-link:hover{
    color:#00695f ;
    
}
.w86{
    width: 50%;
}
.navbar-toggler{
    color: #fff !important;
}
.navbar-toggler-icon{
    color: #fff !important;
}

.app-header{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background-color: #00695f;
    height: 50px;
    z-index: 99;
    align-items: center;
}

.app-header-logo{
    margin-left: 1rem;
}

.app-header .imgLogo{
    width: 300px;
}

.navigation{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.navigation .navigation-items ul li a, .navigation .navigation-items ul li a svg{
    color:white;
    text-decoration: none;
    font-size: 1.5rem;
}

.spacer {
    flex: 1 1;
}

.navigation .navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
   
}

.navigation .navigation-items li {
    padding: 0 0.5rem;
}


.navigation .navigation-items ul li a:hover, .navigation .navigation-items ul li a:active,
.navigation .navigation-items ul li a svg:hover, .navigation .navigation-items ul li a svg:active{
    color: #01b5af 
}


.app-pagination{
    margin-top: 5px;
    height: 30px;
    display: flex;
}
/* .app-pagination ul {
    flex-
} */


.app-drawerbtn{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
}
.app-drawerbtn:focus{
    outline: none;
}
.app-drawerbtn .drawerbtn-line{
    width: 30px;
    height: 2px;
    background: white;

}
.app-side-drawer{
    height: 100%;
    box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
    position: fixed;
    z-index: 101;
   
}
.app-side-drawer-left{
    top:0;
    left:-10px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.app-side-drawer-right{
    top: 0;
    right: -10px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.app-side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.app-side-navbar {
    width: 70%;
    background-color: #00695f;
}
.app-side-drawer ul{
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
}
.app-side-drawer ul.side-drawer-list-navbar{
    justify-content: center;
    padding-left: 0;
    padding-bottom: 50%;
}

.app-side-drawer ul.side-drawer-list-admin{
    justify-content:start;
    padding-left: 0;
    padding-top: 20px;
}
.app-side-drawer ul.side-drawer-list-admin li{
    padding-left: 25px !important;
}

.app-side-drawer ul li{
    margin-bottom: 20px;
    padding-left: 40px;
}

ul.side-drawer-list-navbar > li:first-child{
    position: absolute;
    top:0;
    margin: 2rem 0;
    padding-bottom: 20px;
    border-bottom: #0a4947b2 2px solid ;
    width: 100%;
   
}

ul.side-drawer-list-navbar > li:last-child{
    position: absolute;
    bottom:0;
    padding: 2rem 0;
    border-top: #0a4947b2 2px  solid;
    width: 100%;
}

.side-drawer-list-navbar a{
    color:white;
    text-decoration: none;
    font-size: 1.2rem;
}
.side-drawer-list-navbar a:hover,.app-side-drawer a:active{
    color:#01b5af;
    text-decoration: none;
}

.side-drawer-list-admin a{
    color:#00695f;
    text-decoration: none;
    font-size: 1.2rem;
}
.side-drawer-list-admin a:hover,.app-side-drawer a:active{
    color:#01b5af;
    text-decoration: none;
}

.app-side-drawer li{
    margin: 0.5rem 0;
}

.app-backdrop{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;
}
.h800px{
    height: 800px !important;
}
@media only screen and (min-width:768px) {
    .search-box:hover > .search-text{
        width: 230px;
    }

    .search-text{
        padding-left: 0;
        width: 0px;
    }

    .app-drawerbtn{
        display: none;
    }
}

@media only screen and (min-width:1px) and (max-width: 768px) {
    .navigation-items{
        display: none;
    }

    .search-box{
        position: absolute;
        top:49%;
        left:80%;
    }
    .search-box:hover{
        left:50%;
    }
    .search-box:hover > .search-text{
        width: 300px;
    }
    .search-box:hover > .search-btn{
        position: absolute;
        right: 0px;
        padding: .5rem .5rem;
    }
    .search-text{
        padding-left: 0;
        width: 0px;
    }

    .loader-cr{
        top:40%;
        left: 30%;
    }
    .appTitle{
        font-size: 16px;
        vertical-align: middle;
        vertical-align: -webkit-baseline-middle;
        
    }

    .app-frame{
        padding:10px; 
        padding-left: 0;
        padding-right: 0;
    }
    
    .expand-body{
        width: 100vw !important;
        margin-left: 0 !important;
        padding: 10px 20px !important;
    }

    .show-body{
        width: 100vw !important;
        margin-left: 0 !important;
        padding: 10px 20px !important;
    }



    
}


/* 
.app-header ul li span{
    font-size: 20px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}
.app-header ul li a,.app-header ul li label {
    font-size: 28px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}

.app-header ul{
    margin-top: 10px;
}
.app-header h2{
    margin-top: 10px;
}
.app-header-sm{
    height: 45px;
    -webkit-transition: height 0.2s; 
    transition: height 0.2s;
}

.app-header-sm h2{
    margin-top: 2px;
}
.app-header-sm ul{
    margin-top: 5px;
}
.app-header-sm ul li a, .app-header-sm ul li label {
    font-size: 20px;
    -webkit-transition: font-size 0.2s;
    transition: font-size 0.2s;
}
.app-header-sm ul li span{
    font-size: 14px;
    -webkit-transition: font-size 0.2s; 
    transition: font-size 0.3s;
}
.app-header-sm  .app-header-menu ul li{
    padding: 8px;
}


.app-header-title{
    color:white;
    float: left;
    padding-left: 20px;
}
.app-header-menu{
    float: right;
    color:white;
    text-align: left;
    padding-right: 20px;
}

.app-header-menu li{
    list-style: none;
}
.app-header-menu ul li{
    display: inline;
    padding: 15px;
    
}
.app-header-menu ul li a,.navbar-light .navbar-nav .nav-link,.navbar-brand{
    color:white !important;    
} */

.scroll-app-box{
    overflow-y: auto;
    height: 60vh;
}
.erport-actions {
  float: right;
  color: white;
  text-align: left;
  padding-right: 20px;
}

.erport-actions li {
  list-style: none;
}
.erport-actions ul li {
  display: inline;
  padding: 15px;
}
.erport-actions ul li a {
  color: #b5539d;
}
.icon-view {
  color: #00695f;
}
.icon-view svg {
  margin: 0 auto !important;
}
.titleSubGrup span {
  color: #01b5af;
  text-transform: uppercase;
}

.div-button-right {
  width: 100%;
  text-align: right;
}
/* Estilo para las columnas */
.title-column-evaluation {
  padding: 0;
  background-color: #01b5af;
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  border: 2px solid white;
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Opcional: centra horizontalmente */
  height: 50px; /* Coincide con las filas de contenido */
  box-sizing: border-box;
}

.question-column,
.response-column,
.qppv-column {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: left; /* Centra horizontalmente */
  height: 50px; /* Asegura una altura consistente */
  padding: 0; /* Elimina márgenes y rellenos */
  box-sizing: border-box;
}

.actions-load-m {
  margin-right: 50px !important;
}

@media only screen and (min-width: 1px) and (max-width: 740px) {
  .description-hide {
    display: none !important;
  }
  .icon-menu-expand {
    font-size: 50px !important;
  }
}

.report-cancel .sectionTitle {
  background: #808080;
  border-color: #808080;
}
.report-cancel .summarySection {
  border-color: #808080;
  background-color: #80808033;
}
.titleSubGrup.report-cancel span {
  color: #808080;
}

.summary-modal-body.summary-details .form-step {
  margin: 20px;
  border: 1px solid #018682;
}
.summary-modal-body.summary-details .form-step h3 {
  border: 1px solid #018682;
  background-color: #018682;
  min-height: 36px;
  color: #fff;
  font-size: 1.5rem;
}

.upload-load{
    display: grid;
    grid-template-columns: 65px auto auto 95px;
    border: 1px solid #01b5af;
}

.upload-load div{
    padding: 20px 0;
}
.upload-load.view div{
    padding: 5px 10px;
}


.upload-load .file-accepted-load{
    color:#00695f;
    grid-column-start: 1;
    grid-column-end: 1;
    padding-left: 15px;
    background-color: #f4f4f4;
}

.upload-load .file-name-load{
    grid-column-start: 2;
    grid-column-end: 4;
    padding-left: 15px;
    font-weight: bold;
    line-height: 35px;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

.upload-load .file-action{
    grid-column-start: 4;
    grid-column-end: 4;
    padding-left: 15px;
    padding-top: 30px;
}

.upload-load.view .file-action{
    padding-top: 15px;
}

.my-dropzone{
    border: 1px solid rgba(#000, 0.1);
    width: 100%;
    height: 100px;
    display: block;
    border: 1px solid #01b5af;
    box-sizing: border-box;
    
    background-image: linear-gradient(135deg,rgba(0,0,0,.03)25%, transparent 25%, transparent 50%, rgba(0,0,0,.03)50%, rgba(0,0,0,.03)75%, transparent 75%, transparent);
    background-color: #FAFCFD;
    background-size: 16px 16px;
}
.my-dropzone p{
    padding: 35px 0;
    font-weight: bold;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
}

.action-delete{
    color:#dc3545;
    text-align: center;
}
.action-delete :hover{
    color: #c82333;
}

.action-download{
    color:#17a2b8;
    text-align: center;
}
.action-download :hover{
    color: #138496;
}
.action-edit{
    color:#28a745;
    text-align: center;
}
.action-edit :hover{
    color: #218838;
}
.card-actions div {
  padding: 0;
  width: 100%;
  text-align: right;
}

.card-actions .card-actions-button {
  width: 10%;
}

.step {
  font-size: 1.2em !important;
  background-color: #e8e8e8 !important;
  color: #000 !important;
  font-weight: normal !important;
  padding: 0.68em 0.76em !important;
  border-radius: 15% !important;
  border: #e8e8e8 3px solid !important;
}

.step.step-success {
  background-color: #01b5af !important;
  color: #fff !important;
  border: #00695f 2.5px solid !important;
  font-weight: bold !important;
}

.step.step-primary {
  background-color: #01b5af !important;
  color: #fff !important;
  border: #01b5af 3px solid !important;
  font-weight: bold !important;
}
.step-label {
  background-color: transparent !important;
  margin-top: -10px !important;
}
.step-label.step-label-success {
  color: #00695f !important;
}
.step-label.step-label-primary {
  color: #01b5af !important;
}
.no-scroll {
  height: 100% !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.home-cim {
    scroll-behavior: smooth;
    font-family: "Montserrat", sans-serif;
    width: 100% !important;
}

.home-cim div.content-wrap {
    width: 100%;
    padding: 70px 10%;
    margin: 0px auto;
    display: block;
    max-width: 1200px;
    position: relative;
    box-sizing: border-box
}

.home-cim div.full-page-wrap {
    width: 100vw;
    height: 100vh;
    padding: 40px 8%;
    margin: 0px;
    position: relative;
    box-sizing: border-box
}

.home-cim div.full-page-wrap#inicio {
    padding: 0px
}

.home-cim div.div-50 {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box
}

.home-cim div.div-33 {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
    box-sizing: border-box
}

.home-cim div.div-25 {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box
}

.home-cim p {
    font-size: 1em;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    color: #333;
    text-align: center;
    display: block;
    max-width: 840px;
    margin: 1em auto
}

.home-cim p.left-align {
    text-align: left
}

.home-cim a,
.home-cim a:link,
.home-cim a:visited {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: #B64C92
}

.home-cim a.button {
    border: 1px solid #FFF;
    padding: 10px 14px 11px;
    text-decoration: none;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 10px
}

.home-cim .mobile-only {
    display: none
}

ul.main-menu {
    width: 725px;
    margin: 0px;
    position: absolute;
    top: 26px;
    right: 50px;
    z-index: 15;
    text-align: right
}

.home-cim ul.main-menu li {
    list-style-type: none;
    display: inline-block;
    color: #333;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 11px 8px;
    border-radius: 10px
}

/* .top-menu-wrap:hover{
    background-color:#ffffff33
} */
.actions-wrap-main {
    /* background: none !important; */
    padding: 40px 2% 150px !important;
    max-width: 100% !important;
    position: relative;
}

.report-main-wrap {
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

button.login-btn {
    width: 150px;
    height: 40px;

    color: #fff;
    border-radius: 40px;
    background-color: transparent;
    border-color: #fff;
    outline: none;
}

.login-form button.login-btn.inverse {
    background-color: #01b5af;
    color: #fff;
    border-color: transparent;
}

.login-form input:focus {
    outline: #01b5af;
    border-color: #01b5af;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    color: #01b5af;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}

.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: transparent;
    border-color: #01b5af;
    outline: none;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    border-color: #fff;
    outline: none;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, .25);
}

.home-cim ul.main-menu li:last-child {
    margin-right: 0px;
    margin-left: 5px;
    border: 1px solid #333
}

.home-cim ul.main-menu li a,
.home-cim ul.main-menu li a:link,
.home-cim ul.main-menu li a:visited {
    color: #333;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none
}

.home-cim ul.main-menu li.active-nav a,
.home-cim ul.main-menu li.active-nav a:link,
.home-cim ul.main-menu li.active-nav a:visited {
    background: #fff;
    border: 4.5px solid #B64C92
}

.home-cim ul.main-menu.white-menu {
    top: 43px
}

.home-cim ul.main-menu.white-menu li {
    color: #FFF;
    border-color: #FFF
}

.home-cim ul.main-menu.white-menu li:last-child {
    border: 1px solid #FFF
}

.home-cim ul.main-menu.white-menu li:last-child:hover {
    background: #FFF;
    color: #50AAA9;
    cursor: pointer;
}

.home-cim ul.main-menu.white-menu li a,
.home-cim ul.main-menu.white-menu li a:link,
.home-cim ul.main-menu.white-menu li a:visited {
    color: #FFF
}

.home-cim div#floating-menu {
    position: fixed;
    top: -200px;
    left: 0px;
    background: #FFF;
    z-index: 20;
    width: 100%;
    padding: 20px 30px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.45);
    box-sizing: border-box
}

.home-cim div#floating-menu.show-menu {
    top: 0px
}

div#floating-menu img {
    width: 100px
}

.home-cim div.mobile-bt {
    display: none
}

.home-cim div.mobile-menu-wrap {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 177, 169, 0.9);
    padding: 90px 10%;
    display: none;
    box-sizing: border-box
}

.home-cim ul.mobile-menu-nav {
    margin: 0px auto;
    display: block;
    width: 100%;
    padding: 0px;
    max-width: 280px
}

ul.mobile-menu-nav li {
    list-style-type: none;
    padding: 14px 0px;
    text-align: center;
    border-bottom: 1px solid #FFF;
    color: #FFF;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 300
}

ul.mobile-menu-nav li:last-child {
    border-bottom: none
}

ul.mobile-menu-nav li a,
ul.mobile-menu-nav li a:link,
ul.mobile-menu-nav li a:link:visited {
    text-decoration: none;
    color: #FFF;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 300
}

ul.mobile-menu-nav li i {
    font-size: 14px
}

ul.menu-lateral {
    position: fixed;
    width: 1px;
    border-left: 1px solid #c4c4c4;
    height: 190px;
    right: 60px;
    top: 50%;
    margin-top: -135px;
    padding: 0px;
    z-index: 20
}

ul.menu-lateral li {
    width: 16px;
    height: 16px;
    background: #efefef;
    list-style-type: none;
    position: relative;
    display: block;
    margin: 0px 0px 20px -8px;
    cursor: pointer;
    border-radius: 50%;

}

ul.menu-lateral li:not(:last-child):hover {
    background: #fff;
    border: 5px solid #B64C92;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

ul.menu-lateral li.active-nav,
ul.menu-lateral li.active-nav:hover {
    margin: 0px 0px 20px -10px;
    width: 20px;
    height: 20px;
    background: #B64C92;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}


ul.menu-lateral li span {
    position: absolute;
    display: none;
    top: -5px;
    right: 30px;
    width: 120px;
    background: #B64C92;
    color: #FFF;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    padding: 8px 10px;
    border-radius: 4px
}

ul.menu-lateral li.faqs-lateral span {
    top: -13px
}

ul.menu-lateral li:last-child {
    margin: 0px 0px 0px -8px;
    background: none;
    color: #B64C92
}

ul.menu-lateral li:last-child:hover {
    font-size: 22px;
    margin: -5px 0px 20px -11px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

ul.menu-lateral li .siw {
    width: 20px;
    height: 20px;
    z-index: 10;
}

div.div-arrow-right {
    position: absolute;
    right: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid #B64C92;
    top: 50%;
    margin-top: -6px
}

div.carrousel-content {
    position: relative;
    z-index: 1;
    margin-top: 8%;
    width: 100%;
    max-width: 800px;
    padding: 0px 8%;
    box-sizing: border-box
}

div.carrousel-content hr {
    width: 80px;
    height: 10px;
    background: #FFF;
    border: none;
    margin-top: 30px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2)
}

div.carrousel-img-wrap {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    background-size: cover;
    z-index: 0;
    background: #50AAA9
}

div.carrousel-img-wrap div {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    opacity: 1
}

div.carrousel-img-wrap div.slide-1 {
    background: url(/static/media/bkg-1-dsk.2e88538c.jpg) center center no-repeat
}

div.carrousel-img-wrap div.slide-2 {
    background: url(/static/media/bkg-2-dsk.7d786c07.jpg) center center no-repeat
}

div.carrousel-img-wrap div.slide-3 {
    background: url(/static/media/bkg-3-dsk.ce718678.jpg) center center no-repeat
}

div.carrousel-img-wrap div.slide-4 {
    background: url(/static/media/bkg-4-dsk.bcd0f140.jpg) center center no-repeat
}

div.top-menu-wrap {
    width: 100%;
    position: relative;
    padding: 30px 40px;
    z-index: 5;
    box-sizing: border-box;
    -webkit-transition: background-color 400ms linear;
    transition: background-color 400ms linear
}

h1 {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    color: #333;
    font-size: 2em;
    margin: 1em 0px;
    z-index: 1
}

h1.main-header {
    text-transform: uppercase;
    font-size: 4.2em;
    color: #FFF;
    text-align: left;
    line-height: 0.94em;
    margin: 0px;
    text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.2)
}

img#main-logo {
    position: relative;
    width: 140px
}

.home-arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-size: 20px;
    margin-left: -10px;

    z-index: 5;
    cursor: pointer
}

.home-arrow.arrow-1 {
    color: #FFF !important;
}

.home-arrow.arrow-2 {
    color: rgba(255, 255, 255, 0.445);
}

.home-arrow.arrow-3 {
    color: rgba(0, 0, 0, 0.322) !important;
}

div.main-content {
    width: 100%;
    max-width: 800px;
    margin: 50px 0px 10px;
    background: #FFF;
    border-left: 5px solid #B64C92;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    position: relative;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2)
}

div.main-content div.main-c-left {
    display: inline-block;
    margin: 0px;
    vertical-align: top;
    color: #333;
    text-align: left;
    font-size: 17px;
    padding: 20px 30px;
    width: 70%;
    box-sizing: border-box
}

div.main-content div.main-c-right {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 0px;
    width: 30%;
    height: 100%;
    text-align: center;
    background: #B64C92;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer
}

.home-cim div.main-content div.main-c-right a,
.home-cim div.main-content div.main-c-right a:link {
    color: #FFF;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    margin-top: -15px
}

.home-cim div.main-content div.quienes-somos-home {
    padding: 20px 30px;
    width: 60%;
    box-sizing: border-box
}

.home-cim div.main-content div.quienes-somos-home a {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase
}

.home-cim div.main-content.linked-content {
    max-width: 500px;
    margin: 70px 0px 10px
}

.home-cim div.main-content.linked-content div.main-c-right {
    width: 40%
}

.home-cim div.main-content.classic-mode {
    background: none;
    border-left: none;
    text-align: center;
    box-shadow: 0px 0px 0px 0px transparent
}

.home-cim div.main-content.classic-mode a.action-button {
    display: inline-block;
    width: 185px;
    margin: 0px 30px 0px 0px;
    cursor: pointer;
    text-align: center
}

.home-cim div.main-content.classic-mode a.action-button i {
    margin-right: 10px
}

div.main-content span {
    color: #B64C92;
    font-weight: bold
}

.home-cim p.objetivos-stit {
    margin: 30px auto;
    font-size: 18px
}

ul.objetivos {
    width: 100%;
    max-width: 900px;
    margin: 0px auto;
    display: block;
    padding: 0px
}

.home-cim ul.objetivos li {
    list-style-type: none;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding: 10px 40px 10px 70px;
    position: relative;
    box-sizing: border-box
}

ul.objetivos li p {
    display: inline-block;
    text-align: left
}

.home-cim div.icono-objetivo {
    width: 55px;
    height: 55px;
    color: #808080;
    padding-top: 12px;
    text-align: center;
    border: 1px solid #808080;
    font-size: 25px;
    position: absolute;
    top: 18px;
    left: 0px;
    border-radius: 50%;
    box-sizing: border-box
}

.home-cim div.actions-wrap {
    background: url(/static/media/bkg-reportes-dsk.9ff223c7.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 110px 10% 150px !important;
    max-width: 100% !important;
    position: relative
}

.home-cim div.actions-wrap-main p,
div.actions-wrap p {
    color: #FFF
}

.home-cim div.actions-wrap-main p.actions-tit,
.home-cim div.actions-wrap p.actions-tit {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 50px
}

.home-cim div.actions-wrap-main p.action-stit,
.home-cim div.actions-wrap p.action-stit {
    font-size: 20px !important;
    font-weight: bold !important
}

.home-cim div.actions-wrap-main div.div-50,
.home-cim div.actions-wrap div.div-50 {
    padding: 5px 40px 30px;
    text-align: center
}

.home-cim div.actions-wrap-main div.div-50 p,
.home-cim div.actions-wrap div.div-50 p {
    font-weight: 300;
    font-size: 14px;
    max-width: 290px;
    display: block;
    margin: 20px auto
}

.home-cim div.dotted-side {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(33%, #fff), color-stop(0%, rgba(255, 255, 255, 0)));
    background-image: linear-gradient(#fff 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 8px;
    background-repeat: repeat-y
}

a.action-button {
    font-weight: bold;
    border: none;
    background: #FFF;
    color: #50AAA9;
    display: block;
    width: 185px;
    margin: 30px auto 0px;
    cursor: pointer
}

a.action-button i {
    margin-right: 10px
}

div.report-wrap {
    width: 80%;
    max-width: 500px;
    display: block;
    margin: 20px auto
}

div.report-wrap .action-button {
    text-align: center
}

.home-cim div.separator {
    display: block;
    margin: 0px auto;
    width: 130px;
    text-align: center
}

.home-cim div.separator hr {
    border: none;
    background: #50AAA9;
    margin: 15px 0px;
    width: 45px;
    height: 1px;
    display: inline-block;
    vertical-align: middle
}

div.separator div.sq-s {
    width: 7px;
    height: 7px;
    border: 1px solid #B64C92;
    display: inline-block;
    margin: 0px 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.home-cim div.img-separator {
    width: 100%;
    height: 80vh;
    background: url(/static/media/bkg-3-dsk.ce718678.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed
}

.home-cim div.compania-sep {
    background: url(/static/media/bkg-4-dsk.bcd0f140.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed
}

.home-cim div.cover-wrap {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
    display: none
}

.home-cim div.modal-wrap {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin: 0px auto;
    margin-top: 150px;
    background: #FFF;
    padding: 22px 25px;
    text-align: center;
    display: block;
    border-radius: 40px;
    box-sizing: border-box
}

.home-cim div.modal-wrap p {
    color: #333;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px
}

.home-cim div.modal-wrap p.modal-tit {
    font-size: 17px;
    font-weight: bold
}

.home-cim div.modal-wrap p.modal-q {
    font-weight: bold;
    font-size: 16px
}

.home-cim div.modal-wrap p.modal-d {
    font-size: 12px
}

.home-cim div.modal-wrap p.modal-d a,
.home-cim div.modal-wrap p.modal-d a:link,
.home-cim div.modal-wrap p.modal-d a:link:visited {
    color: #50AAA9
}

.home-cim div.modal-wrap a.round-button {
    color: #50AAA9;
    border: 2px solid #50AAA9;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    width: 64px;
    height: 64px;
    font-weight: bold;
    padding-top: 16px;
    vertical-align: top;
    margin: 10px;
    border-radius: 50%;
    box-sizing: border-box
}

.home-cim div.modal-wrap hr {
    border: none;
    height: 1px;
    background: #999;
    margin: 20px auto 10px
}

ul.company-list {
    display: inline-block;
    width: 28%;
    vertical-align: top;
    margin: 12px 2.5%;
    padding: 0px
}

.home-cim ul.company-list li {
    color: #333;
    font-size: 14px;
    margin-bottom: 2px
}

.home-cim ul.company-list li.company-country {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 4px;
    color: #333;
    list-style-type: none
}

.home-cim div.footer-wrap {
    width: 100%;
    padding: 45px 6% 40px;
    background: #151e1d;
    border-top: 7px solid #50AAA9;
    box-sizing: border-box
}

.home-cim img.logo-footer {
    width: 100%;
    height: auto;
    padding: 5px;
}

ul.footer-list {
    margin: 0px;
    padding: 0px;
    width: 100%
}

.home-cim ul.footer-list li {
    list-style-type: none;
    font-family: "Montserrat", sans-serif;
    font-size: 12px !important;
    font-weight: 300;
    color: #FFF;
    margin-bottom: 3px
}

.home-cim ul.footer-list li a {
    font-size: 12px !important;
    font-weight: 300;
    color: #FFF;
    cursor: pointer
}

.home-cim div.login-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 80;
    padding: 30px 6%;
    background: rgba(0, 177, 169, 0.9);
    display: none;
    box-sizing: border-box
}

.home-cim div.login-content {
    text-align: center;
    width: 330px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -165px;
    margin-top: -200px
}

.home-cim div.login-content p {
    font-style: 18px;
    color: #FFF;
    text-align: center;
    font-family: "Montserrat", sans-serif
}

.home-cim div.login-content a,
.home-cim div.login-content a:link,
.home-cim div.login-content a:link:visited {
    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 13px
}

div.login-content a.login-bt,
div.login-content a:link.login-bt,
div.login-content a:link:visited.login-bt {
    display: block;
    width: 95px;
    margin: 30px auto;
    font-size: 15px;
    border: 1px solid #FFF
}

div.login-content img {
    width: 160px;
    display: block;
    margin: 0px auto 40px
}

form#login-frm {
    width: 100%;
    margin: 0px 0px 15px;
    padding: 0px
}

form#login-frm input {
    width: 100%;
    border: 2px solid #FFF;
    background: #FFF;
    font-family: "Montserrat", sans-serif;
    color: #333;
    padding: 6px 10px 7px;
    margin: 5px 0px;
    box-sizing: border-box;
    border-radius: 15px
}

div#close-login {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer
}

div#close-login i {
    font-size: 40px;
    color: #FFF;
    cursor: pointer
}

ul.faqs {
    padding: 0px;
    width: 100%;
    max-width: 800px;
    display: block;
    margin: 30px auto 60px
}

ul.faqs li {
    list-style-type: none;
    width: 100%;
    text-align: center
}

ul.faqs li a.faq-q {
    font-weight: bold;
    color: #50AAA9;
    display: block;
    margin-bottom: 15px;
    cursor: pointer
}

ul.faqs li p {
    font-size: 15px;
    max-height: 0px;
    overflow: hidden;
    margin: 0px
}

.home-cim ul.faqs li p.fake-list {
    display: block;
    max-width: 600px;
    text-align: left;
    margin: 0px auto
}

.home-cim div.report-carrousel {
    padding: 20px 2px !important;
    z-index: 10;
    width: 500px;
    position: absolute;
    top: 200px;
    right: 200px;
    background: rgba(0, 0, 0, 0.46);
    border-radius: 6px;
}

/* div.actions-wrap-main p:hover{
    background-color: rgba(0, 0, 0, 0.46);
    border-radius: 5px;
    text-shadow: none;
} */

.home-cim div.report-carrousel p.action-text {
    font-size: 1.2em;
    color: #FFF;
    /* text-shadow: -1px 2px 2px rgba(0,0,0,1); */
}

/* 
h1.main-header, div.report-carrousel p.action-stit{
    text-shadow: -1px 2px 2px rgba(0,0,0,0.5);  
}*/
.home-cim div.report-carrousel .button.action-button {
    color: #50AAA9;
    background: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    border: 1px solid #FFF;
    box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.3);
    /* box-shadow: -1px 4px 2px rgba(0,0,0,0.3);  */
}

/* div.report-carrousel .button.action-button:hover
{
    font-size: 17.5px;
    margin-top:28px;
    width: 200px;
    -webkit-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease;
}  */
.home-cim div.report-carrousel .button.action-button:active {
    /* color:#fff;
    background: #01b5af;
    border: 1px solid #01b5af; */
    box-shadow: inset 0 0 20px
}

.home-cim .arrow-home {
    position: absolute;
    left: 50%;
    bottom: 0;
}

.home-cim .arrow-home .arrow-1 {
    -webkit-animation: MoveUpDown-1 1s linear infinite;
            animation: MoveUpDown-1 1s linear infinite;
}

.home-cim .arrow-home .arrow-2 {
    -webkit-animation: MoveUpDown-2 1 linear infinite;
            animation: MoveUpDown-2 1 linear infinite;
}

.home-cim .arrow-home .arrow-3 {
    -webkit-animation: MoveUpDown-3 1s linear infinite;
            animation: MoveUpDown-3 1s linear infinite;
}

@-webkit-keyframes MoveUpDown-1 {

    0%,
    100% {
        bottom: 0;
    }

    50% {
        bottom: 20px;
    }
}

@keyframes MoveUpDown-1 {

    0%,
    100% {
        bottom: 0;
    }

    50% {
        bottom: 20px;
    }
}

@-webkit-keyframes MoveUpDown-2 {

    0%,
    100% {
        bottom: 5px;
    }

    50% {
        bottom: 20px;
    }
}

@keyframes MoveUpDown-2 {

    0%,
    100% {
        bottom: 5px;
    }

    50% {
        bottom: 20px;
    }
}

@-webkit-keyframes MoveUpDown-3 {

    0%,
    100% {
        bottom: 3px;
    }

    50% {
        bottom: 20px;
    }
}

@keyframes MoveUpDown-3 {

    0%,
    100% {
        bottom: 3px;
    }

    50% {
        bottom: 20px;
    }
}

.home-cim div.carrousel-content {
    margin-top: 0;
    padding: 0 50px !important
}

.home-cim div.report-carrousel {
    top: 160px;
    right: 120px;
    box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
}

.home-cim h1.main-header {
    text-transform: uppercase;
    font-size: 5em;
    font-weight: 600;
    color: #FFF;
    text-align: left;
    line-height: 0.94em;
    margin: 0px;
    padding-top: 40px;
    width: 90%;
    text-shadow: -2px 5px 5px rgba(0, 0, 0, 0.6);
}

.home-cim .top-menu-wrap.top-menu-wrap-solid {
    z-index: 12;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    padding-top: 0;
    padding-bottom: 0;
    height: 58px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.home-cim .top-menu-wrap.top-menu-wrap-solid .logoCim {
    max-width: 100px !important;
    height: auto;
    top: 8px;
}

.home-cim .top-menu-wrap.top-menu-wrap-solid ul {
    top: 10px !important;
}

.home-cim div.top-menu-wrap {
    padding: 15px 40px;
}

.home-cim ul.main-menu.white-menu {
    top: 25px;
}

.home-cim a.close-modal-home,
.home-cim a.close-modal-home:visited {
    float: right;
    color: #a4a4a4;
}

.home-cim a.close-modal-home:hover {
    color: #444444;
}

.home-cim div.cover-wrap.show {
    display: block;
}

.home-cim div.modal-wrap a.round-button:hover {
    color: #fff;
    border: 2px solid #50AAA9;
    background: #50AAA9;
}


/* .top-menu-wrap.top-menu-wrap-solid a {
    color:#50AAA9;
}
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li a{
    color:#50AAA9;
}
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li:last-child{
    border:1px solid #50AAA9;
    color: #50AAA9;
} 
.top-menu-wrap.top-menu-wrap-solid ul.main-menu.white-menu li:last-child:hover{
    background: #50AAA9;
    color: #fff;
    cursor: pointer;
} */



/* FORM */

.home-cim div.modal-wrap.form {
    position: relative;
    width: 100%;
    max-width: 80%;
    margin: 0px auto;
    margin-top: 10px;
    background: #FFF;
    padding: 0;
    text-align: center;
    display: block;
    border-radius: 0px 40px 5px 5px;
    box-sizing: border-box;
    height: 97vh;

    /* background: #3e8483; */
    background: #50AAA9;
}

.home-cim div.modal-wrap.form input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    vertical-align: top;
    margin: 0px 5px 10px 0px;
}


.home-cim div.modal-wrap.form label,
.home-cim div.modal-wrap.form span,
.home-cim div.modal-wrap.form h3,
.home-cim div.modal-wrap.form h6 {
    color: #fff;
}

.home-cim div.modal-wrap.form .from-footer small {
    color: #fff !important;
}

.home-cim div.modal-wrap.form .dropdown-item span,
.home-cim div.modal-wrap.form input {
    color: #333 !important;
}

.home-cim div.modal-wrap.form .dropdown-menu a {
    color: #aaa !important;
}

.home-cim div.form .form-title {
    width: 100%;
    text-align: left;
    padding: 25px 25px;
    background: #B64C92;
    border-top-right-radius: 30px;
    box-sizing: border-box;
}

.home-cim div.form .form-title h2 {
    color: #fff;
}

.home-cim div.modal-wrap.form {
    text-align: left;
}

.home-cim div.form .form-step {
    padding: 20px 50px;
    overflow-y: auto;
    height: 70vh;
}

.home-cim div.form .from-footer {
    margin: 0 auto;
    width: 80%;
    text-align: center;
}

.home-cim a.close-modal-home,
.home-cim a.close-modal-home.close-form:visited {
    float: right;
    color: #e0e0e0;
}

.home-cim a.close-modal-home.close-form:hover {
    color: #d0d0d0;
}

/* .home-cim div.form .form-step .step1{
    margin: 0 auto;
    width: 60%;
} */


.home-cim .form-summary {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #FFF;
    margin: 10px 10px;

}

.home-cim .form-summary .form-step * {
    color: #333 !important;
}

.home-cim .form-summary .form-step {
    height: auto !important;
}

.home-cim .form-finished {
    margin: 30px auto;
    width: 80%;
    text-align: center;
}

.home-cim .form-finished * {
    color: #fff !important;
}

.home-cim .form-finished p.finished {
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 400;
}

.home-cim .form-finished p.finished .identificador {
    font-size: 24px !important;
}

.home-cim .form-finished a {
    color: #B64C92 !important;
    font-size: 24px !important;
}

.home-cim .form-finished a:hover {
    text-decoration: underline;

}


@media screen and (max-width: 760px) {
    .home-cim div.report-carrousel {
        top: 45%;
        right: auto;
        box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
    }

    .home-cim h1.main-header {
        text-transform: uppercase;
        font-size: 2.5em;
    }
    
    .carousel .slide img {
        width: 100%;
        vertical-align: top;
        border: 0;
        height: 100%;
    }

    .home-cim div.form .from-footer {
      display: inline;
    }

    .home-cim div.form .from-footer .login-btn {
       font-size: 10px !important;
    }

    .login-close{
        z-index: 1000;
    }

    .home-cim .modal-wrap {
        overflow-y: auto;
    }

    .home-cim div.modal-wrap.form {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0px auto;
        margin-top: 10px;
        background: #FFF;
        padding: 0;
        text-align: center;
        display: block;
        border-radius: 0px 40px 5px 5px;
        box-sizing: border-box;
        height: 97vh;
        /* background: #3e8483; */
        background: #50AAA9;
    }
}

.g-recaptcha {
    transform:scale(0.2);
    -webkit-transform:scale(0.2);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.button-next {
  background-color: #01b5af !important;
  color: white !important;
}

.button-next:disabled {
  background-color: #538786 !important;
}

.background-login {
  background-color: rgb(1, 181, 175);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  z-index: 1;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.background-login.lock {
  background-color: rgba(1, 181, 175, 1);
  z-index: 8887;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}

.background-login.lock.open {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.login-form {
  background: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  top: 40px !important;
}

@media only screen and (min-width: 1px) and (max-width: 600px) {
  .background-img {
    background-position: center;
    background-size: 280%;
    z-index: 1;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0vh;
    left: 0vw;
  }
}
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .background-img {
    background-position: center;
    background-size: 150%;
    z-index: 1;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0vh;
    left: 0vw;
  }
}

.collapse.show.navbar-collapse {
  background-color: #00695f !important;
}
.collapse.show.navbar-collapse ul {
  float: left;
  padding-left: 20px;
}
.collapse.show.navbar-collapse .dropdown-menu {
  background-color: #00695f !important;
}

.summary-modal .modal-content {
  margin-top: -25px !important;
}
.summary-modal-body {
  height: 73vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.summary-modal-body .form-summary .form-step {
  border: 1px solid #01b5af;
  margin-bottom: 20px !important;
}

.summary-modal-body .form-summary .form-step .form-step-row {
  padding: 0px 20px !important;
}
.summary-modal-body .form-summary h3 {
  border: 1px solid #01b5af;
  background-color: #01b5af;
  min-height: 42px;
  color: #fff;
  padding: 3px 5px;
}
.summary-modal-body .form-summary h2 {
  display: none;
}
.noborder {
  border: none !important;
}

.captcha-adjust {
  margin-right: 10vh;
}

.ark-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0;
  border: 1px solid #ddd;
  min-width: 100%;
  margin-bottom: 1.25rem;
  color: #333;
  border-collapse: collapse; }

.ark-table:last-child {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem; }

.ark-thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit; }

.ark-table td {
  padding: .15rem 1.25rem;
  vertical-align: middle;
  border-top: 1px solid #ddd; }

.ark-table tr {
  -webkit-transition: background-color ease-in-out .15s;
  transition: background-color ease-in-out .15s;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit; }

.ark-table-filters:hover input {
  display: inline !important;
  display: initial !important; }

.ark-table-filters input {
  display: none !important;
  display: block;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='-300 -10 1000 500'  fill-rule='evenodd'%3E%3Cpath fill='rgba(0, 0, 0,0.2)' d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'%3E%3C/path%3E%3C/svg%3E") right/contain no-repeat; }

.ark-table-filters input:focus {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='-300 -10 1000 500'  fill-rule='evenodd'%3E%3Cpath fill='rgba(26, 179, 148,0.6)' d='M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z'%3E%3C/path%3E%3C/svg%3E") right/contain no-repeat; }

