.login-form {
  width: 40%;
  margin: 8% auto;
  padding: 15px;
  border-radius: 15px;
  background-color: transparent;
  text-align: center;
  text-align: -webkit-center;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  font-size: 1.2rem;
  color: white;
}
.login-form a,
login-form a:link {
  color: #fff !important;
}
.login-form-group {
  text-align: left !important;
}
.error {
  text-align: center;
  text-align: -webkit-center;
}
.login-form img {
  display: block;
  margin: 0 auto;
}
.inputWithIcon input {
  height: 35px !important;
  transition: 0.3s;
  border-radius: 20px;
}
.inputWithIcon {
  margin-bottom: 0;
  padding-bottom: 0;
}
.inputWithIcon svg {
  position: relative;
  left: 20%;
  top: -30px;
  color: #aaa;
  margin-left: 5px;
  margin-top: -10px;
}
.inputWithIcon input:focus {
  border-color: #fff;
}
.inputWithIcon input:focus + svg {
  color: #fff;
}

.login-form input,
.login-form button {
  width: 60%;
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  font-size: 1rem;
}
.login-form button.login-btn {
  width: 150px;
  height: 40px;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  border-radius: 40px;
  background-color: transparent;
  border-color: #fff;
  outline: none;
}
.login-form button.login-btn.inverse {
  background-color: #01b5af;
  color: #fff;
  border-color: transparent;
}
.login-form input:focus {
  outline: #01b5af;
  border-color: #01b5af;
}
.login-close {
  color: white !important;
  font-size: 4rem;
  position: absolute;
  top: 10px;
  right: 10%;
  text-decoration: none;
}
.login-close:hover svg,
.login-close:active svg {
  color: rgba(255, 255, 255, 0.459);
}

.invalid-tooltip {
  position: relative !important;
  width: 250px !important;
  left: 22% !important;
  margin-bottom: 20px;
}

.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #01b5af;
  background-color: #fff;
  border-color: #fff;
  outline: none;
}
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):active,
.login-btn.inverse.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: transparent;
  border-color: #01b5af;
  outline: none;
}
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  border-color: #fff;
  outline: none;
}
.login-btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
}

.forgetpass {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.forgetpass:hover,
.forgetpass:active {
  color: white;
}

.divforgetpass {
  text-align: center;
  text-align: -webkit-center;
}

.login-flip {
  background-color: rgba(1, 181, 175);
}

@media only screen and (min-width: 1px) and (max-width: 600px) {
  .login-form {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding-top: 25%;
    padding-bottom: 60%;
    margin-top: 0;
  }
  .invalid-tooltip {
    position: relative !important;
    width: 250px !important;
    left: 2% !important;
  }

  .buttonProgress {
    margin-top: 80px;
  }
  .login-form-group {
    margin-top: 25%;
  }

  .login-form-group input {
    width: 100%;
    height: 45px !important;
    font-size: 18px !important;
    margin-top: 15px !important;
  }
  .inputWithIcon svg {
    position: relative;
    left: 20%;
    top: -35px;
    color: #aaa;
    margin-left: 5px;
  }
  .login-button {
    margin-top: 95px;
  }
  .img-label {
    width: 95%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .login-form {
    width: 60%;
    margin: 12% auto;
  }
}

@media only screen and (min-width: 1400px) {
  .login-form {
    width: 30%;
    margin: 12% auto;
  }
  .login-form-group input {
    width: 60%;
  }
}
